import {
  Box,
  Flex,
  SimpleGrid,
  Icon,
  Text,
  useMediaQuery,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import {
  TbBeer,
  TbBuildingStore,
  TbCalendarEvent,
  TbMapSearch,
  TbSquareRoundedPlusFilled,
} from "react-icons/tb";
import { useLocation } from "react-router-dom";
import appConfig from "../../config/appConfig";
import useWindowSize from "../../hooks/useWindowSize";
import Loading from "../Loading/Loading";
import withLoading, { WithLoadingProps } from "../WithLoading/WithLoading";
import StartSquareButton from "./StartSquareButton";

type StartProps = WithLoadingProps & {};

const Start = ({ setIsLoading }: StartProps) => {
  // State
  const [videoIsLetterboxed, setVideoIsLetterboxed] = useState<boolean>();
  const [videoSource, setVideoSource] = useState("./video-landscape.mp4");

  // Location
  const location = useLocation();

  // Define window size
  const windowSize = useWindowSize();

  // Define is landscape
  const [isLandscape] = useMediaQuery("(orientation: landscape)");

  // Hook
  useEffect(() => {
    // If window size width, window size height, or is landscape are null or undefined
    if (
      windowSize.width == null ||
      windowSize.height == null ||
      isLandscape == null
    )
      return;

    // Define aspect
    const aspect = windowSize.width / windowSize.height;

    // If aspect less than 16/9 and is landscape
    if (aspect <= 16 / 9 && isLandscape) {
      // Set video is letterboxed
      setVideoIsLetterboxed(true);

      return;
    }

    // If aspect greater than or equal to 16/9 and is landscape
    if (aspect > 16 / 9 && isLandscape) {
      // Set video is letterboxed
      setVideoIsLetterboxed(false);

      return;
    }

    // If aspect less than 2048/2738 and not is landscape
    if (aspect <= 2048 / 2738 && !isLandscape) {
      setVideoIsLetterboxed(true);

      return;
    }

    // If aspect greater than or equal to 2048/2738 and not is landscape
    if (aspect > 2048 / 2738 && !isLandscape) {
      setVideoIsLetterboxed(false);

      return;
    }
  }, [isLandscape, windowSize]);

  // Hook
  useEffect(() => {
    // Set is loading
    setIsLoading(false);
  }, [setIsLoading]);

  // Hook
  useEffect(() => {
    // If is landscape
    if (isLandscape) {
      // Set video source
      setVideoSource("./video-landscape.mp4");
    } else {
      // Set video source
      setVideoSource("./video-portrait.mp4");
    }
  }, [isLandscape]);

  return (
    <Flex className="Start" height="100%" overflow="hidden" position="relative">
      <Box
        backgroundColor="#1c1e2b"
        className="StartVideoContainer"
        position="relative"
        display="grid"
        placeItems="center"
        height="100%"
        width="100%"
        margin="0px auto"
      >
        <video
          autoPlay
          className="Video"
          key={videoSource}
          loop
          muted
          playsInline
          style={{
            backgroundColor: "black",
            height: "100vh",
            left: "50%",
            objectFit: "contain",
            position: "fixed",
            top: "50%",
            transform: "translate(-50%, -50%)",
            width: "100vw",
            zIndex: 999,
          }}
        >
          <source src={videoSource} type="video/mp4" />
        </video>
      </Box>
      <Box
        className="Content"
        height={
          videoIsLetterboxed
            ? isLandscape
              ? "56.25vw"
              : "133.69140625vw"
            : isLandscape
            ? "100vh"
            : "100vh"
        }
        left="50%"
        overflow="hidden"
        position="fixed"
        top="50%"
        transform="translate(-50%,-50%)"
        width={
          videoIsLetterboxed
            ? isLandscape
              ? "100vw"
              : "100vw"
            : isLandscape
            ? "177.777777778vh"
            : "74.799123447vh"
        }
        zIndex={9999}
      >
        <Box
          className="ContentContainer"
          float="right"
          paddingRight={
            videoIsLetterboxed
              ? isLandscape
                ? "5vw"
                : "10vw"
              : isLandscape
              ? "9vh"
              : "9vh"
          }
          paddingTop={
            videoIsLetterboxed
              ? isLandscape
                ? "26.25vw"
                : "46vw"
              : isLandscape
              ? "47vh"
              : "35vh"
          }
          width={
            videoIsLetterboxed
              ? isLandscape
                ? "39.4vw"
                : "87vw"
              : isLandscape
              ? "70vh"
              : "65vh"
          }
        >
          <SimpleGrid
            className="Grid"
            columns={
              videoIsLetterboxed
                ? isLandscape
                  ? [3, 3, 3]
                  : [3, 3, 4]
                : isLandscape
                ? [2]
                : [4]
            }
            spacing={[4, 4, 6]}
          >
            {!appConfig.disableLocations && (
              <Box className="Box">
                <StartSquareButton state={{ from: location }} to="/locations">
                  <VStack>
                    <Icon
                      as={
                        appConfig.locationsExpoOnly
                          ? TbBuildingStore
                          : TbMapSearch
                      }
                      boxSize={8}
                    />
                    <Text as="span" fontSize="0.75rem" fontWeight="normal">
                      Find a{" "}
                      {appConfig.locationsExpoOnly ? "booth" : "location"}
                    </Text>
                  </VStack>
                </StartSquareButton>
              </Box>
            )}
            {!appConfig.disableNewExhibitors && (
              <Box className="Box" height="100%">
                <StartSquareButton
                  state={{ from: location }}
                  to="/early-stage-expo"
                >
                  <VStack>
                    <Icon as={TbSquareRoundedPlusFilled} boxSize={8} />
                    <Text as="span" fontSize="0.75rem" fontWeight="normal">
                      Early Stage Expo
                    </Text>
                  </VStack>
                </StartSquareButton>
              </Box>
            )}
            {!appConfig.disablePubCrawl && (
              <Box className="Box" height="100%">
                <StartSquareButton state={{ from: location }} to="/pub-crawl">
                  <VStack>
                    <Icon as={TbBeer} boxSize={8} />
                    <Text as="span" fontSize="0.75rem" fontWeight="normal">
                      Pub crawl
                    </Text>
                  </VStack>
                </StartSquareButton>
              </Box>
            )}
            {!appConfig.disableSchedule && (
              <Box className="Box" height="100%">
                <StartSquareButton state={{ from: location }} to="/schedule">
                  <VStack width="100%">
                    <Icon as={TbCalendarEvent} boxSize={8} />
                    <Text as="span" fontSize="0.75rem" fontWeight="normal">
                      Search schedule
                    </Text>
                  </VStack>
                </StartSquareButton>
              </Box>
            )}
          </SimpleGrid>
        </Box>
      </Box>
    </Flex>
  );
};

export default withLoading(Start, <Loading message="Loading app" />);
