import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useGlobalState } from "../../context/GlobalStateProvider";
import MIMapView, { mapViewCameraFocusOnOptions } from "../MIMapView/MIMapView";
import useLocationById from "../../hooks/useLocationById";
import {
  MappedinDirections,
  MapView,
  TMappedinDirective,
} from "@mappedin/mappedin-js";
import {
  Box,
  Flex,
  Heading,
  Hide,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
import withLoading, { WithLoadingProps } from "../WithLoading/WithLoading";
import Loading from "../Loading/Loading";
import DirectionsStartAndDestination from "./DirectionsStartAndDestination";
import DirectionsInstructions from "./DirectionsInstructions";
import DirectionsInstructionsHeader from "./DirectionsInstructionsHeader";
import CollapsingSidebarLayout from "../Layouts/CollapsingSidebarLayout/CollapsingSidebarLayout";
import DirectionsAccessibleRoutesSwitch from "./DirectionsAccessibleRoutesSwitch";
import MapWrapper from "../MapWrapper/MapWrapper";
import theme from "../../theme";
import DirectionsQRCode from "./DirectionsQRCode";
import appConfig from "../../config/appConfig";
import { useSearchParams } from "react-router-dom";
import withIdleTimeout from "../WithIdleTimeout/WithIdleTimeout";
import usePolygonById from "../../hooks/usePolygonById";
import customMarriotDirections from "../../helpers/customMarriotDirections";

const journeyDrawOptions = {
  pathOptions: { color: theme.colors.brandPrimary[300], drawDuration: 0 },
};

type DirectionsProps = WithLoadingProps & {};

const Directions = ({ setIsLoading }: DirectionsProps) => {
  // Global context
  const {
    setState,
    state: {
      defaultMapRotation,
      defaultMapTilt,
      mapView,
      startLocation,
      venue,
    },
  } = useGlobalState();

  // Params
  const { destinationLocationId, destinationPolygonId } = useParams();

  // Search params
  const [searchParams] = useSearchParams();

  // State
  const [accessibleRoutesOnly, setAccessibleRoutesOnly] = useState<boolean>();
  const [directions, setDirections] = useState<MappedinDirections>();

  // Navigate
  const navigate = useNavigate();

  // Destination location
  const destinationLocation = useLocationById(
    venue?.locations,
    destinationLocationId
  );

  // Destination polygon
  const destinationPolygon = usePolygonById(
    venue?.polygons,
    destinationPolygonId
  );

  // Accessible routes only switch callback
  const accessibleRoutesOnlySwitchCallback = useCallback(
    (switchValue: boolean) => {
      // If switchValue true
      if (switchValue) {
        // Set accessible routes only
        setAccessibleRoutesOnly(true);
      } else {
        // Set accessible routes only
        setAccessibleRoutesOnly(false);
      }
    },
    []
  );

  // Set map view
  const setMapView = useCallback(
    (mv: MapView | undefined) => {
      // If map view is null or undefined
      if (mv == null) return;

      // Set state
      setState((prev) => ({ ...prev, mapView: mv }));
    },
    [setState]
  );

  // Show controls labels
  const showControlsLabels = useBreakpointValue({
    base: false,
    md: true,
  });

  // Hook
  useEffect(() => {
    // Define accessible routes only search param
    const accessibleRoutesOnlySearchParam = searchParams.get(
      "accessibleRoutesOnly"
    );

    // If accessible routes only search param is "true"
    if (accessibleRoutesOnlySearchParam === "true") {
      // Set accessible routes only
      setAccessibleRoutesOnly(true);
    } else {
      // Set accessible routes only
      setAccessibleRoutesOnly(false);
    }

    return () => {
      //
    };
  }, [searchParams]);

  // Hook
  useEffect(() => {
    // If destination location is null or undefined
    if (destinationLocation == null) return;

    // If start location is null or undefined
    if (startLocation == null) return;

    // Define directions
    let _directions = startLocation.directionsTo(
      destinationPolygon ?? destinationLocation.polygons[0],
      {
        accessible: accessibleRoutesOnly,
      }
    );

    // If directions is null or undefined
    if (_directions == null) return;

    console.log(startLocation);

    // If start location tags contain
    if (
      startLocation.tags?.includes("firstStepFaceEscalator") &&
      _directions.instructions.length > 0
    ) {
      // Set first directions instructions
      _directions.instructions[0].instruction =
        "Depart in direction of first arrow";
    }

    // If destination location is Marriott
    if (destinationLocation.tags?.includes("Marriott")) {
      // Define directions
      _directions = customMarriotDirections(_directions);
    }

    // Loop over directions instructions with map to filter out ommitted location names
    _directions.instructions.map((instructionItem: TMappedinDirective) => {
      // If instruction item instruction does not include " at "
      if (!instructionItem.instruction.includes(" at ")) return instructionItem;

      // Define location name
      const instructionItemInstructionLocationName =
        instructionItem.instruction.split(" at ")[1];

      // Define instruction item node map
      const instructionItemNodeMap = instructionItem.node.map;

      // If instruction item node map is null or undefined
      if (instructionItemNodeMap == null) return instructionItem;

      // Define instruction item instruction location
      const instructionItemInstructionLocation =
        instructionItemNodeMap.locations.find(
          (location) => location.name === instructionItemInstructionLocationName
        );

      // If instruction item instruction location is null or undefined
      if (instructionItemInstructionLocation == null) return instructionItem;

      // If instruction item instruction location tags includes "omitNameFromDirections"
      if (
        instructionItemInstructionLocation.tags?.includes(
          "omitNameFromDirections"
        )
      ) {
        // Define instruction item instruction
        instructionItem.instruction =
          instructionItem.instruction.split(" at ")[0];
      }

      return instructionItem;
    });

    // Set directions
    setDirections(_directions);
  }, [
    accessibleRoutesOnly,
    destinationLocation,
    destinationPolygon,
    setIsLoading,
    startLocation,
  ]);

  // Hook on directions
  useEffect(() => {
    // If map view is null or undefined
    if (mapView == null) return;

    // If directions is null or undefined
    if (directions == null) return;

    try {
      // Remove all interactive polygons
      mapView.removeAllInteractivePolygons();

      // Highlight destination location polygons
      destinationLocation?.polygons
        .filter(({ map }) => venue?.maps.map(({ id }) => id).includes(map.id))
        .forEach((polygon) => {
          mapView.setPolygonColor(
            polygon,
            theme.colors.brandPrimary[500] ?? "red"
          );
        });

      // If directions have no path
      if (directions.path.length === 0) {
        // Log directions response
        console.log(directions);

        throw new Error("Directions do not have a path");
      }

      // Define directions first map
      const directionsFirstMap = directions.path[0].map;

      // Draw journey
      mapView.Journey.draw(directions, journeyDrawOptions);

      // Define directions first map directions path nodes
      const directionsFirstMapDirectionsPathNodes = directions.path.filter(
        (node) => node.map.id === directionsFirstMap.id
      );

      // Set map
      mapView.setMap(directionsFirstMap).then(() => {
        // Focus map map view camera
        mapView.Camera.focusOn(
          {
            nodes: directionsFirstMapDirectionsPathNodes,
          },
          {
            ...mapViewCameraFocusOnOptions,
            rotation: ((defaultMapRotation ?? 0) * Math.PI) / 180,
            tilt: ((defaultMapTilt ?? 0) * Math.PI) / 180,
            duration: 0,
          }
        ).then(() => {
          // Set timeout
          setTimeout(() => {
            // Set is loading
            setIsLoading(false);
          }, 200);
        });
      });
    } catch (e) {
      // Log error
      console.log(e);

      // Navigate to error page
      navigate("/error");
    }

    return () => {
      // Clear journey
      mapView.Journey.clear();
    };
  }, [
    defaultMapRotation,
    defaultMapTilt,
    destinationLocation,
    destinationLocation?.polygons,
    directions,
    mapView,
    navigate,
    setIsLoading,
    venue?.maps,
  ]);

  return (
    <CollapsingSidebarLayout
      mainContent={
        <>
          <Hide above="sm">
            <DirectionsStartAndDestination
              startLocation={startLocation}
              destinationLocation={destinationLocation}
            />
          </Hide>
          <MapWrapper mapView={mapView}>
            <MIMapView
              controls={{
                mapSelectDisabled: true,
                mapSelectShowLabel: appConfig.locationsExpoOnly
                  ? false
                  : showControlsLabels,
                zoomIn: useBreakpointValue({ base: false, md: true }),
                zoomInShowLabel: appConfig.locationsExpoOnly
                  ? false
                  : showControlsLabels,
                zoomOut: useBreakpointValue({ base: false, md: true }),
                zoomOutShowLabel: appConfig.locationsExpoOnly
                  ? false
                  : showControlsLabels,
              }}
              defaultMapRotation={defaultMapRotation}
              defaultMapTilt={defaultMapTilt}
              setMapView={setMapView}
              venue={venue}
            />
          </MapWrapper>
          <DirectionsAccessibleRoutesSwitch
            accessibleRoutesOnly={accessibleRoutesOnly}
            changeCallback={accessibleRoutesOnlySwitchCallback}
          />
        </>
      }
      sidebarContent={
        <>
          <Hide below="sm">
            <DirectionsStartAndDestination
              startLocation={startLocation}
              destinationLocation={destinationLocation}
            />
          </Hide>
          <DirectionsInstructionsHeader
            instructions={directions?.instructions}
          />
          <Box
            backgroundColor={useColorModeValue("gray.50", "gray.800")}
            flexGrow={1}
            overflow="hidden"
            minHeight={0}
          >
            <DirectionsInstructions instructions={directions?.instructions} />
          </Box>
          {!appConfig.disableDirectionsQrCode &&
            startLocation &&
            destinationLocation && (
              <Box
                backgroundColor="brandPrimary.500"
                className="DirectionsQrCodeContainer"
                color="white"
                display={["none", "none", "block"]}
                width="100%"
              >
                <Box
                  className="Container"
                  paddingX={[4, 4, 6]}
                  paddingY={[2, 2, 4]}
                  width="100%"
                >
                  <Flex
                    alignItems="center"
                    className="Row"
                    justifyContent="space-between"
                    marginX={[-2, -2, -3]}
                  >
                    <Box className="RowItem" paddingX={[2, 2, 3]}>
                      <Heading size="md">
                        Scan to view <br />
                        on your mobile device
                      </Heading>
                    </Box>
                    <Box
                      className="RowItem"
                      paddingX={[2, 2, 3]}
                      width={[
                        "33%",
                        "33%",
                        "33%",
                        "33%",
                        "33%",
                        "33%",
                        "25%",
                        "33%",
                        "33%",
                      ]}
                    >
                      <Box backgroundColor="white" padding={[1, 1, 2]}>
                        <DirectionsQRCode
                          value={`${appConfig.qrCodeUrl}/directions/${destinationLocation?.id}/${destinationPolygon?.id}?startLocationId=${startLocation?.id}&accessibleRoutesOnly=${accessibleRoutesOnly}`}
                        />
                      </Box>
                    </Box>
                  </Flex>
                </Box>
              </Box>
            )}
        </>
      }
      mainContentMinHeight={["60%", "60%", "100%"]}
    />
  );
};

export default withIdleTimeout(
  withLoading(Directions, <Loading message="Loading directions" />)
);
