import {
  Box,
  HStack,
  Icon,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { MappedinLocation } from "@mappedin/mappedin-js";
import { TbDotsVertical, TbLocation, TbMapPin } from "react-icons/tb";

type DirectionsStartAndDestinationProps = {
  destinationLocation?: MappedinLocation;
  startLocation?: MappedinLocation;
};

const DirectionsStartAndDestination = ({
  destinationLocation,
  startLocation,
}: DirectionsStartAndDestinationProps) => {
  return (
    <Box
      backgroundColor={useColorModeValue("gray.200", "gray.700")}
      className="DirectionsStartAndDestination"
      paddingX={[4, 4, 6]}
      paddingY={[2, 2, 4]}
      width="100%"
    >
      <VStack spacing={[-2, -2, -1]} alignItems="start">
        <HStack spacing={[4, 4, 6]} width="100%" alignItems="center">
          <Box paddingTop={0.5}>
            <Icon
              as={TbLocation}
              color={useColorModeValue("blue.300", "blue.500")}
            />
          </Box>
          <Box>
            <Text>{startLocation?.name}</Text>
          </Box>
        </HStack>
        <Box>
          <Icon as={TbDotsVertical} paddingTop={0.5} />
        </Box>
        <HStack spacing={[4, 4, 6]} width="100%" alignItems="center">
          <Box paddingTop={0.5}>
            <Icon
              as={TbMapPin}
              color={useColorModeValue("red.300", "red.500")}
            />
          </Box>
          <Box>
            <Text>{destinationLocation?.name}</Text>
          </Box>
        </HStack>
      </VStack>
    </Box>
  );
};

export default DirectionsStartAndDestination;
