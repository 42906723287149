import { E_CAMERA_EVENT, MapView } from "@mappedin/mappedin-js";
import { MouseEvent, ReactNode, useCallback, useEffect, useState } from "react";
import MIMapViewControlLabel from "../MIMapViewControlLabel";
import MIMapViewZoomOutDefault from "./MIMapViewZoomOutDefault";

type MIMapViewZoomOutProps = {
  component?: ReactNode;
  mapView?: MapView;
  showLabel?: boolean;
};

const MIMapViewZoomOut = ({
  component,
  mapView,
  showLabel = false,
}: MIMapViewZoomOutProps) => {
  // State
  const [enabled, setEnabled] = useState<boolean>(true);

  // Handle changed
  const handleChanged = useCallback(() => {
    // If map view null or undefined
    if (mapView == null) return;

    // If zoom less than max zoom
    if (mapView?.Camera.zoom < mapView.Camera.maxZoom) {
      // Set enabled
      !enabled && setEnabled(true);
    } else {
      // Set enabled
      enabled && setEnabled(false);
    }
  }, [enabled, mapView]);

  // Handle click
  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    // Animate map view camera
    mapView?.Camera.animate(
      {
        zoom: mapView?.Camera.zoom + 1500,
      },
      { duration: 200 }
    ).then(() => {
      // Trigger user interaction end functions
      mapView.Camera._subscribers.USER_INTERACTION_END.forEach(
        (fn: Function) => {
          // Call function
          fn();
        }
      );
    });
  };

  // Hook
  useEffect(() => {
    // On map view camera changed
    mapView?.Camera.on(E_CAMERA_EVENT.CHANGED, handleChanged);

    return () => {
      // Off map view camera changed
      mapView?.Camera.off(E_CAMERA_EVENT.CHANGED, handleChanged);
    };
  }, [handleChanged, mapView?.Camera]);

  return (
    <div
      onClick={handleClick}
      style={{
        cursor: "pointer",
        opacity: enabled ? 1 : 0.5,
        pointerEvents: enabled ? "all" : "none",
      }}
    >
      <MIMapViewControlLabel labelText="Zoom out" show={showLabel}>
        {component ? component : <MIMapViewZoomOutDefault />}
      </MIMapViewControlLabel>
    </div>
  );
};

export default MIMapViewZoomOut;
