import { useEffect, useState } from "react";

import { useSearchParams } from "react-router-dom";

const useDefaultMapRotation = () => {
  // Search params
  const [searchParams] = useSearchParams();

  // State
  const [defaultMapRotation, setDefaultMapRotation] = useState<number>(0);

  // Hook
  useEffect(() => {
    // Search params default map rotation
    const searchParamsDefaultMapRotation =
      searchParams.get("defaultMapRotation");

    // Local storage default map rotation
    const localStorageDefaultMapRotation =
      localStorage.getItem("defaultMapRotation");

    // Define default map rotation
    const _defaultMapRotation = parseInt(
      searchParamsDefaultMapRotation ?? localStorageDefaultMapRotation ?? "0"
    );

    // If default map rotation is NaN
    if (isNaN(_defaultMapRotation)) {
      // Set default map rotation
      setDefaultMapRotation(0);

      return;
    }

    // Set default map rotation
    setDefaultMapRotation(_defaultMapRotation);

    return () => {};
  }, [searchParams]);

  // Hook
  useEffect(() => {
    // If default map rotation is null or undefined
    if (defaultMapRotation == null) return;

    // Set local storage default map rotation
    localStorage.setItem("defaultMapRotation", String(defaultMapRotation));

    return () => {};
  }, [defaultMapRotation]);

  return defaultMapRotation;
};

export default useDefaultMapRotation;
