import axios from "axios";

const getScheduleData = async (t: number) => {
  // GET
  const data = axios
    .get(
      `https://apps.d3gproductions.com/apipulls/RSAC2023SessionsAPI.cfm?t=${t}`
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error.toJSON());
    });

  return data;
};

export default getScheduleData;
