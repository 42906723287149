import {
  Box,
  Center,
  Spinner,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";

type LoadingProps = {
  message?: string;
};

const Loading = ({ message }: LoadingProps) => {
  return (
    <Box
      backgroundColor={useColorModeValue("#1c1e2b", "gray.800")}
      bottom={0}
      color={useColorModeValue("white", "white")}
      className="Loading"
      height="100vh"
      left={0}
      position="fixed"
      right={0}
      top={0}
      zIndex={999999}
      width="100vw"
    >
      <Center height="100%" width="100%">
        <VStack>
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />
          <Text>{message ? message : "Loading"}</Text>
        </VStack>
      </Center>
    </Box>
  );
};

export default Loading;
