import { Button } from "@chakra-ui/button";
import React from "react";

type SearchJsonDataLoadMoreResultsButtonProps = {
  callback?: () => void;
};

const SearchJsonDataLoadMoreResultsButton = ({
  callback,
}: SearchJsonDataLoadMoreResultsButtonProps) => {
  // Handle click
  const handleClick = () => {
    // Execute callback
    callback && callback();
  };

  return (
    <Button
      className="SearchJsonDataLoadMoreResultsButton"
      colorScheme="brandPrimary"
      onClick={handleClick}
      width="100%"
    >
      Load more results
    </Button>
  );
};

export default SearchJsonDataLoadMoreResultsButton;
