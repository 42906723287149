import {
  Badge,
  Box,
  HStack,
  ListItem,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { TMappedinDirective } from "@mappedin/mappedin-js";
import React, { MouseEvent } from "react";
import DirectionsInstructionsIcon from "./DirectionsInstructionsIcon";

type DirectionsInstructionsItemProps = {
  clickCallback?: (idx: number, instruction: TMappedinDirective) => void;
  idx: number;
  instruction: TMappedinDirective;
};

const DirectionsInstructionsItem = ({
  clickCallback,
  idx,
  instruction,
}: DirectionsInstructionsItemProps) => {
  // Handle click
  const handleClick = (
    event: MouseEvent<HTMLLIElement>,
    idx: number,
    instruction: TMappedinDirective
  ) => {
    // Call click callback
    clickCallback && clickCallback(idx, instruction);
  };

  return (
    <ListItem
      _hover={{
        backgroundColor: "blackAlpha.300",
      }}
      borderBottomColor={useColorModeValue("blackAlpha.200", "whiteAlpha.200")}
      borderBottomWidth="0.0625rem"
      cursor="pointer"
      data-stepnumber={idx}
      key={idx}
      onMouseDown={(e) => handleClick(e, idx, instruction)}
      paddingX={[4, 4, 6]}
      paddingY={[2, 2, 4]}
    >
      <HStack spacing={[4, 4, 6]} alignItems="top" width="100%">
        <Box>
          {instruction?.action && (
            <DirectionsInstructionsIcon
              action={instruction.action}
              atLocation={instruction.atLocation}
            />
          )}
        </Box>
        <Box>
          <Box width="100%">
            {instruction?.action?.type === "ExitVortex" && (
              <Badge
                colorScheme="brandPrimary"
                fontSize="smaller"
                marginBottom={2}
              >
                {instruction?.action?.fromMap.name} to{" "}
                {instruction?.action?.toMap.name}
              </Badge>
            )}
          </Box>
          <Text fontSize="smaller">{instruction.instruction}</Text>

          {instruction.distance > 0 && (
            <Text fontSize="smaller" marginTop={[1, 1, 2]}>
              in about {Math.ceil(instruction.distance * 3.28084) + "ft"}
            </Text>
          )}
        </Box>
      </HStack>
    </ListItem>
  );
};

export default DirectionsInstructionsItem;
