import {
  Mappedin,
  MappedinLocation,
  MapView,
  TCameraTargets,
} from "@mappedin/mappedin-js";
import { useEffect, useState } from "react";
import useMapView from "../../hooks/useMapView";
import MIMapViewControls, { MIMapViewControlsProps } from "./MIMapViewControls";
import "./MIMapView.css";

export const mapViewCameraFocusOnOptions = { minZoom: 1500 };

type MIMapViewProps = {
  controls?: MIMapViewControlsProps;
  defaultMapRotation?: number;
  defaultMapTilt?: number;
  height?: string;
  resizing?: boolean;
  setMapView?: (mapView: MapView | undefined) => void;
  startLocation?: MappedinLocation;
  venue?: Mappedin;
  width?: string;
};

const MIMapView = ({
  controls,
  defaultMapRotation,
  defaultMapTilt,
  height,
  resizing,
  setMapView,
  startLocation,
  venue,
  width,
}: MIMapViewProps) => {
  // State
  const [mapEl, setMapEl] = useState<HTMLElement | null>();

  // MapView
  const mapView = useMapView(mapEl, venue);

  // Hook on map view
  useEffect(() => {
    // If map view null or undefined
    if (mapView == null) return;

    // Set map view camera
    mapView.Camera.set({
      rotation: ((defaultMapRotation ?? 0) * Math.PI) / 180,
      tilt: ((defaultMapTilt ?? 0) * Math.PI) / 180,
    });

    // Set map view safe area insets
    mapView.Camera.setSafeAreaInsets({
      top: 30,
      left: 30,
      bottom: 30,
      right: 30,
    });

    // Set map view min zoom
    mapView.Camera.minZoom = 1500;

    // Label locations with flat labels
    mapView.FlatLabels.labelAllLocations();

    // Make map interactive
    mapView.addInteractivePolygonsForAllLocations();

    // Set map view
    setMapView && setMapView(mapView);

    return () => {
      // Set map view
      setMapView && setMapView(undefined);

      // Remove flat labels from all locations
      mapView?.FlatLabels.removeAll();
    };
  }, [defaultMapRotation, defaultMapTilt, mapView, setMapView, startLocation]);

  // Hook
  useEffect(() => {
    // Get elem
    const elem = document.getElementById("map");

    // If elem is defined
    if (elem) {
      // Set map el
      setMapEl(elem);
    }

    return () => {
      // Set map el
      setMapEl(undefined);
    };
  }, []);

  // Hook on resize
  useEffect(() => {
    // If resizing
    if (resizing) return;

    // Define focus targets
    let focusTargets: TCameraTargets = {
      polygons: mapView?.currentMap.polygons,
    };

    // Define current map current path nodes
    const currentMapCurrentPathNodes = mapView?.currentPath
      ? mapView?.currentPath.filter(
          (node) => node.map.id === mapView.currentMap.id
        )
      : [];

    // If current map current path nodes length
    if (currentMapCurrentPathNodes?.length) {
      // Define focus targets
      focusTargets = { nodes: currentMapCurrentPathNodes };
    }

    // Focus map view
    mapView?.Camera.focusOn(focusTargets, {
      ...mapViewCameraFocusOnOptions,
      duration: 0,
    });
  }, [mapView, resizing, startLocation]);

  return (
    <div
      id="map"
      style={{
        height: height ?? "100%",
        left: "50%",
        position: "absolute",
        top: "0",
        transform: "translateX(-50%)",
        width: width ?? "100%",
      }}
    >
      <MIMapViewControls
        {...controls}
        defaultMapRotation={defaultMapRotation}
        defaultMapTilt={defaultMapTilt}
        mapView={mapView}
        startLocation={startLocation}
        venue={venue}
      />
    </div>
  );
};

export default MIMapView;
