const MIMapViewZoomInDefault = () => {
  return (
    <div
      className="MIMapViewZoomInDefault"
      style={{
        backgroundColor: "white",
        borderRadius: "99rem",
        boxShadow: "0 0.125rem 0.25rem rgba(0,0,0,0.25)",
        height: "3rem",
        position: "relative",
        width: "3rem",
      }}
    >
      <div
        style={{
          left: "50%",
          position: "absolute",
          top: "50%",
          transform: "translate(-50%, -50%)",
          userSelect: "none",
        }}
      >
        <div
          style={{
            lineHeight: 1,
            position: "relative",
            textAlign: "center",
          }}
        >
          <span
            style={{
              color: "black",
              display: "inline-block",
              fontFamily: "Arial, Helvetica, sans-serif",
              fontSize: "1.5rem",
              fontWeight: "bold",
              left: "0.03125rem",
              position: "relative",
            }}
          >
            &#x2b;
          </span>
        </div>
      </div>
    </div>
  );
};

export default MIMapViewZoomInDefault;
