import { useEffect, useState } from "react";
import { getVenue, Mappedin, TGetVenueOptions } from "@mappedin/mappedin-js";

function useVenue(options: TGetVenueOptions, lastCalled?: number) {
  // Store the venue object in a state variable
  const [venue, setVenue] = useState<Mappedin | undefined>();

  // Fetch data asynchronously whenever options are changed
  useEffect(() => {
    // Define ignore
    let ignore = false;

    // Define fetch data
    const fetchData = async () => {
      try {
        // Define data
        const data = await getVenue({
          ...options,
          headers: { lastUpdateRequested: String(lastCalled) },
        });

        // Update state variable after data is fetched
        if (!ignore) {
          // Set venue
          setVenue(data);
        }
      } catch (e) {
        // Handle error
        console.log(e);

        // Set venue
        setVenue(undefined);
      }
    };

    // Call fetch data
    fetchData();

    return () => {
      // Define ignore
      ignore = true;
    };
  }, [lastCalled, options]);

  // Return the venue object
  return venue;
}

export default useVenue;
