import { MouseEvent, useEffect, useRef, useState } from "react";
import {
  Button,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from "@chakra-ui/react";
import { ChangeEvent, FocusEvent } from "react";
import { TbSearch, TbX } from "react-icons/tb";
import Keyboard from "../Keyboard/Keyboard";

type SearchJsonDataInputProps = {
  blurCallback?: (value: string) => void;
  changeCallback?: (value: string) => void;
  focusCallback?: (value: string) => void;
};

const SearchMILocationsWidgetInput = ({
  blurCallback,
  changeCallback,
  focusCallback,
}: SearchJsonDataInputProps) => {
  // Ref
  const keyboardRef = useRef<any>(null);

  // State
  const [showKeyboard, setShowKeyboard] = useState<boolean>(false);
  const [value, setValue] = useState<string>("");

  // Handle blur
  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    // Set show keyboard
    setShowKeyboard(false);

    // Call blur callback
    blurCallback && blurCallback(event.target.value);
  };

  // Handle input change
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    // Set value
    setValue(event.target.value);
  };

  // Handle clear button click
  const handleClearButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    // If value is defined
    if (value) {
      // Set value
      setValue("");
    }
  };

  // Handle blur
  const handleFocus = (event: FocusEvent<HTMLInputElement>) => {
    // Set show keyboard
    setShowKeyboard(true);

    // Call focus callback
    focusCallback && focusCallback(event.target.value);
  };

  // Hook
  useEffect(() => {
    // Define keyboard ref current
    const keyboardRefCurrent = keyboardRef.current;

    // Set input on keyboard ref current
    keyboardRefCurrent.setInput(value);

    // Call change callback
    changeCallback && changeCallback(value);

    return () => {
      // Set input on keyboard ref current
      keyboardRefCurrent.setInput("");
    };
  }, [changeCallback, value]);

  return (
    <>
      <InputGroup>
        <InputLeftElement>
          <Icon as={TbSearch} color="white" />
        </InputLeftElement>
        <Input
          _placeholder={{ color: "inherit", opacity: 0.5 }}
          autoComplete="off"
          backgroundColor="brandPrimary.500"
          className="SearchJsonDataInput"
          color="white"
          id="keyboard-target"
          inputMode="none"
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={handleFocus}
          placeholder="Search schedule"
          value={value}
          variant="flushed"
        />
        {value && (
          <InputRightElement paddingX={2} width="auto">
            <Button
              flexShrink={0}
              onClick={handleClearButtonClick}
              rightIcon={<TbX />}
              size="xs"
            >
              Clear
            </Button>
          </InputRightElement>
        )}
      </InputGroup>
      <Keyboard
        keyboardRef={keyboardRef}
        setShowKeyboard={setShowKeyboard}
        setTargetInputValue={setValue}
        showKeyboard={showKeyboard}
      />
    </>
  );
};

export default SearchMILocationsWidgetInput;
