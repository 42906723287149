import {
  Box,
  Button,
  Center,
  Link,
  useColorModeValue,
  VisuallyHidden,
} from "@chakra-ui/react";
import appConfig from "../../config/appConfig";

const Header = () => {
  return (
    <Box
      backgroundColor={useColorModeValue("black", "black")}
      className="Header"
      paddingX={[4, 4, 6]}
      paddingY={[2, 2, 4]}
    >
      <Center width="100%">
        <Box maxWidth={["14rem", "14rem", "16rem"]} paddingY={[2, 2, 2]}>
          <img src="./rsac2023-logo.png" alt="RSAC 2023 Logo" />
        </Box>
      </Center>

      {!appConfig.disableStart && (
        <Button
          as={Link}
          opacity={0}
          href="/"
          position="fixed"
          top={0}
          right={0}
          size="lg"
          zIndex={999999}
        >
          <VisuallyHidden>Manual Reset</VisuallyHidden>
        </Button>
      )}
    </Box>
  );
};

export default Header;
