import { useEffect, useState } from "react";
import {
  Badge,
  Box,
  Container,
  Flex,
  Heading,
  Hide,
  HStack,
  Image,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import appConfig from "../../config/appConfig";
import { useGlobalState } from "../../context/GlobalStateProvider";
import DirectionsQRCode from "../Directions/DirectionsQRCode";
import { MappedinLocation, MappedinPolygon } from "@mappedin/mappedin-js";
import DirectionsStartAndDestination from "../Directions/DirectionsStartAndDestination";
import withIdleTimeout from "../WithIdleTimeout/WithIdleTimeout";
import useVenue from "../../hooks/useVenue";
import { venueOptions } from "../../App";
import withLoading, { WithLoadingProps } from "../WithLoading/WithLoading";
import Loading from "../Loading/Loading";

type NewExhibitorsProps = WithLoadingProps & {};

const NewExhibitors = ({ setIsLoading }: NewExhibitorsProps) => {
  // Global context
  const {
    state: { startLocation },
  } = useGlobalState();

  // State
  const [destinationLocation, setDestinationLocation] =
    useState<MappedinLocation>();
  const [destinationPolygon, setDestinationPolygon] =
    useState<MappedinPolygon>();

  // Venue
  const venue = useVenue(venueOptions);

  // Hook
  useEffect(() => {
    // If venue null or undefined
    if (venue == null) return;

    // Define destination location
    const _destinationLocation = venue.locations.find(
      (location) => location.tags?.includes("Early Stage Expo")
    );

    // If destination location null or undefined
    if (_destinationLocation == null) return;

    // Define destination polygon
    const _destinationPolygon = _destinationLocation.polygons[0];

    // Set destination location
    setDestinationLocation(_destinationLocation);

    // Set destination polygon
    setDestinationPolygon(_destinationPolygon);

    return () => {
      // Set destination location
      setDestinationLocation(undefined);

      // Set destination polygon
      setDestinationPolygon(undefined);
    };
  }, [venue]);

  // Hook
  useEffect(() => {
    // If destination location is null or undefined
    if (destinationLocation == null) return;

    // If destination polygon is null or undefined
    if (destinationPolygon == null) return;

    // Set is loading
    setIsLoading(false);
  }, [destinationLocation, destinationPolygon, setIsLoading]);

  return (
    <Flex
      className="New Exhibitors"
      direction="row"
      height="100%"
      overflowY="hidden"
    >
      <Container className="Container" maxW="container.2xl" paddingX={0}>
        <HStack height="100%">
          <Flex className="Flex" direction="column" height="100%" width="25%">
            <Flex direction="column" flexGrow={1}>
              <Hide below="sm">
                <DirectionsStartAndDestination
                  startLocation={startLocation}
                  destinationLocation={destinationLocation}
                />
              </Hide>
              <Box
                backgroundColor={useColorModeValue("gray.100", "gray.600")}
                paddingX={[4, 4, 6]}
                paddingY={[2, 2, 4]}
                top={0}
              >
                <Text fontSize="sm" fontWeight="bold">
                  Steps{" "}
                  <Badge
                    position="relative"
                    mb={0.5}
                    ml={2}
                    borderRadius={99}
                    colorScheme="brandPrimary"
                    paddingX={2.5}
                    paddingY={0.5}
                  >
                    1
                  </Badge>
                </Text>
              </Box>
              <Box
                backgroundColor={useColorModeValue("gray.50", "gray.800")}
                flexGrow={1}
                paddingX={[4, 4, 6]}
                paddingY={[2, 2, 4]}
              >
                <Text>
                  To get to the Early Stage Expo head to the 2nd floor of
                  Moscone South and make your way to Room 205.
                </Text>
              </Box>
            </Flex>
            <Box>
              {!appConfig.disableDirectionsQrCode &&
                startLocation &&
                destinationLocation && (
                  <Box
                    backgroundColor="brandPrimary.500"
                    className="DirectionsQrCodeContainer"
                    color="white"
                    display={["none", "none", "block"]}
                    width="100%"
                  >
                    <Box
                      className="Container"
                      paddingX={[4, 4, 6]}
                      paddingY={[2, 2, 4]}
                      width="100%"
                    >
                      <Flex
                        alignItems="center"
                        className="Row"
                        justifyContent="space-between"
                        marginX={[-2, -2, -2]}
                      >
                        <Box className="RowItem" paddingX={[2, 2, 2]}>
                          <Heading size="sm">
                            Scan to view <br />
                            step-by-step directions <br />
                            on your mobile device
                          </Heading>
                        </Box>
                        <Box
                          className="RowItem"
                          paddingX={[2, 2, 2]}
                          width={[
                            "33%",
                            "33%",
                            "33%",
                            "33%",
                            "33%",
                            "33%",
                            "33%",
                            "33%",
                            "33%",
                          ]}
                        >
                          <Box backgroundColor="white" padding={[1, 1, 2]}>
                            <DirectionsQRCode
                              value={`${appConfig.qrCodeUrl}/directions/${
                                destinationLocation?.id
                              }/${destinationPolygon?.id}?startLocationId=${
                                startLocation?.id
                              }&accessibleRoutesOnly=${true}`}
                            />
                          </Box>
                        </Box>
                      </Flex>
                    </Box>
                  </Box>
                )}
            </Box>
          </Flex>
          <Box
            className="Box"
            height="100%"
            paddingX={[4, 4, 6]}
            paddingY={[4, 4, 6]}
            width="41.6%"
          >
            <Heading marginBottom={[4, 4, 6]}>Early Stage Expo</Heading>
            <Text fontSize="xl" marginBottom={4}>
              This exhibit space showcases up-and-coming companies and provides
              you with the opportunity to meet the cybersecurity leaders of
              tomorrow.
            </Text>
            <Heading as="h2" fontSize="2xl" marginBottom={2}>
              Hours
            </Heading>
            <Text fontSize="xl">Wednesday 9:30 AM &ndash; 4:30 PM</Text>
            <Text fontSize="xl">Thursday 9:30 AM &ndash; 3:00 PM</Text>
          </Box>
          <Flex
            alignContent="center"
            className="Box"
            flexDirection="column"
            height="100%"
            justifyContent="center"
            paddingX={[4, 4, 6]}
            paddingY={[4, 4, 6]}
            width="33%"
          >
            <Flex height="80%" justifyContent="center">
              <Image
                src="https://rsac-2023-app-public-assets.s3.us-west-1.amazonaws.com/rsac-2023-ese-floorplan.png"
                height="100%"
                width="auto"
              />
            </Flex>
          </Flex>
        </HStack>
      </Container>
    </Flex>
  );
};

export default withIdleTimeout(
  withLoading(NewExhibitors, <Loading message="Loading" />)
);
