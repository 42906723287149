import NavigationButton from "./NavigationButton";
import { Icon, Text, VStack } from "@chakra-ui/react";
import { TbBeer } from "react-icons/tb";
import { NavigationState } from "./Navigation";

type NavigationPubCrawlButtonProps = {
  navigationState?: NavigationState;
};

const NavigationPubCrawlButton = ({
  navigationState,
}: NavigationPubCrawlButtonProps) => {
  return (
    <NavigationButton
      className="NavigationPubCrawlButton"
      isDisabled={navigationState === NavigationState.PUB_CRAWL ? true : false}
      to="/pub-crawl"
    >
      <VStack justifyContent="center" spacing={1} width="100%">
        <Icon as={TbBeer} boxSize={6} />
        <Text fontSize="0.75rem" fontWeight={100} textTransform="uppercase">
          Pub Crawl
        </Text>
      </VStack>
    </NavigationButton>
  );
};

export default NavigationPubCrawlButton;
