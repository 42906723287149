import { List } from "@chakra-ui/react";
import SearchJsonDataResultsItem from "./SearchJsonDataResultsItem";

type SearchJsonDataResultsProps = {
  results: any[];
};

const SearchJsonDataResults = ({ results }: SearchJsonDataResultsProps) => {
  return (
    <List
      className="SearchJsonDataResults"
      paddingY={[2, 2, 4]}
      spacing={[2, 2, 4]}
    >
      {results.map((result, idx) => (
        <SearchJsonDataResultsItem key={idx} result={result} />
      ))}
    </List>
  );
};

export default SearchJsonDataResults;
