import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import appConfig from "../../config/appConfig";

export type WithIdleTimeoutProps = {
  setIsLoading: Dispatch<SetStateAction<boolean>>;
};

const TIMEOUT = 90;
const WARNING = 15;

const withIdleTimeout = <T extends WithIdleTimeoutProps = WithIdleTimeoutProps>(
  WrappedComponent: React.ComponentType<T>,
  idleCallback?: () => void
) => {
  // Try to create a nice displayName for React Dev Tools.
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || "Component";

  // Creating the inner component. The calculated Props type here is the where the magic happens.
  const ComponentWithIdleTimeout = (
    props: Omit<T, keyof WithIdleTimeoutProps>
  ) => {
    // Disclosure
    const { isOpen, onOpen, onClose } = useDisclosure();

    // Navigate
    const navigate = useNavigate();

    // State
    const [lastInteraction, setLastInteraction] = useState<number>(Date.now());
    const [timeLeft, setTimeLeft] = useState<number>(TIMEOUT);

    // Ref
    const countdownInterval = useRef<NodeJS.Timeout | null>(null);

    // Reset idle timeout
    const resetIdleTimeout = useCallback(() => {
      // Close modal
      onClose();

      // Set last interaction
      setLastInteraction(Date.now());
    }, [onClose]);

    // Hook
    useEffect(() => {
      // Loop over DOM events that trigger reset
      ["click", "scroll", "keypress"].forEach((domEvent) => {
        // Add event listener
        window.addEventListener(domEvent, resetIdleTimeout);
      });

      return () => {
        // Loop over DOM events that trigger reset
        ["click", "scroll", "keypress"].forEach((domEvent) => {
          // Add event listener
          window.removeEventListener(domEvent, resetIdleTimeout);
        });
      };
    }, [resetIdleTimeout]);

    // Hook
    useEffect(() => {
      // If countdown interval current is defined
      if (countdownInterval.current) {
        // Clear interval
        clearInterval(countdownInterval.current);
      }

      // Define countdown interval current
      countdownInterval.current = setInterval(() => {
        // Set time left
        setTimeLeft(
          Math.round(TIMEOUT - (Date.now() - lastInteraction) / 1000)
        );
      }, 200);
    }, [lastInteraction]);

    // Hook
    useEffect(() => {
      // If time left greater than warning
      if (timeLeft > WARNING) return;

      // If time left equals warning
      if (timeLeft === WARNING) {
        // Open modal
        onOpen();
      }

      // If time left less than or equal to zero
      if (timeLeft <= 0) {
        // Close modal
        onClose();

        // Reset idle timeout
        resetIdleTimeout();

        // If idle callback
        if (idleCallback) {
          // Call idle callback
          idleCallback();
        } else {
          // Navigate to root
          // navigate("/");
          window.location.pathname = "/";
        }
      }

      return () => {};
    }, [navigate, onClose, onOpen, resetIdleTimeout, timeLeft]);

    // Hook
    useEffect(() => {
      // If is open
      if (isOpen) return;

      // Reset idle timeout
      resetIdleTimeout();
    }, [isOpen, resetIdleTimeout]);

    return (
      <>
        <WrappedComponent {...(props as T)} />
        <Modal isCentered={true} isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Warning</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              This session will time out in {timeLeft} seconds. Select the
              cancel button or close this window to continue.
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="brandPrimary" mr={3} onClick={onClose}>
                Cancel
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    );
  };

  ComponentWithIdleTimeout.displayName = `withLoading(${displayName})`;

  // Define component without idle timeout
  const ComponentWithoutIdleTimeout = (
    props: Omit<T, keyof WithIdleTimeoutProps>
  ) => {
    return <WrappedComponent {...(props as T)} />;
  };

  return appConfig.disableTimeout
    ? ComponentWithoutIdleTimeout
    : ComponentWithIdleTimeout;
};

export default withIdleTimeout;
