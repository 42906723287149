import { MouseEvent } from "react";
import {
  Box,
  HStack,
  ListItem,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { MappedinLocation } from "@mappedin/mappedin-js";

type SearchMILocationsResultsItemProps = {
  lastItem: boolean;
  clickCallback?: (result: MappedinLocation) => void;
  result: MappedinLocation;
};

const SearchMILocationsResultsItem = ({
  lastItem,
  clickCallback,
  result,
}: SearchMILocationsResultsItemProps) => {
  // Handle list item click
  const handleListItemClick = (
    event: MouseEvent<HTMLLIElement>,
    result: MappedinLocation
  ) => {
    // Call click callback
    clickCallback && clickCallback(result);
  };

  return (
    <ListItem
      _hover={{ backgroundColor: "blackAlpha.100" }}
      borderBottomColor={useColorModeValue("blackAlpha.100", "whiteAlpha.100")}
      borderBottomWidth={lastItem ? 0 : "0.125rem"}
      className="SearchMILocationsResultsItem"
      fontSize="smaller"
      onMouseDown={(e) => handleListItemClick(e, result)}
      paddingX={4}
      paddingY={2}
    >
      <HStack justifyContent="space-between" width="100%">
        <Box>
          <Text>{result?.name}</Text>
        </Box>
      </HStack>
    </ListItem>
  );
};

export default SearchMILocationsResultsItem;
