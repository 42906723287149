import {
  E_SDK_EVENT,
  E_SDK_EVENT_PAYLOAD,
  Mappedin,
  MappedinLocation,
  MappedinPolygon,
  MapView,
} from "@mappedin/mappedin-js";
import Marker from "@mappedin/mappedin-js/renderer/internal/Mappedin.Marker";
import { useCallback, useEffect, useState } from "react";
import useMapClick from "./useMapClick";

/**
 * Maintain an active MappedinLocation; automatically change the selected location when
 * a polygon is clicked; also allow the selected location to be changed externally
 */
export default function useSelectedLocation(
  mapView: MapView | undefined,
  venue: Mappedin | undefined,
  selectedLocationColor?: string,
  showMarker?: boolean
) {
  // Store the active MappedinLocation
  const [selectedLocation, setSelectedLocation] = useState<
    MappedinLocation | undefined
  >();

  // Store the selected location marker
  const [selectedLocationMarker, setSelectedLocationMarker] =
    useState<Marker>();

  // Define clear marker
  const clearSelectedLocationMarker = useCallback(() => {
    // Remove marker
    selectedLocationMarker && mapView?.removeMarker(selectedLocationMarker);
  }, [mapView, selectedLocationMarker]);

  // Define create location marker
  const createLocationMarker = useCallback(
    (location: MappedinLocation) => {
      // Define location marker
      const locationMarker = mapView?.createMarker(
        location.nodes[0],
        `<div style="
          position: absolute;
          bottom: 50%;
          display: flex;
          flex-direction: column;
          justify-content: end;
          left: 50%;
          transform: translateX(-50%);
          width: auto !important;
        ">
          <div style="
            background-color: black;
            color: white;
            padding: 0.5rem; 0.75rem;
            max-width: 12rem;
            word-wrap: normal;
            width: max-content;
            font-family: Open Sans, sans-serif;
            text-align: center;
          ">
            ${location.name}
          </div> 
          <div style="
            position: absolute;
            top: 99%;
            left: 50%;
            transform: translateX(-50%);
            border-width: 0.75rem;
            border-style: solid;
            border-color: black transparent transparent transparent;
          ">
        </div>`
      );

      return locationMarker;
    },
    [mapView]
  );

  // Change the selected location when a polygon is clicked using useMapClick
  useMapClick(
    mapView,
    ({ polygons }: E_SDK_EVENT_PAYLOAD[E_SDK_EVENT.CLICK]) => {
      if (polygons[0] != null && polygons[0].locations[0] != null) {
        // Define polygon
        const polygon = polygons[0];

        // Create writeable type
        type Writeable<T> = { -readonly [P in keyof T]: T[P] };

        // Cast polygon as writeable
        const writeablePolygon = polygons[0] as Writeable<MappedinPolygon>;

        // Define writeable polygon location
        const writeablePolygonLocation = writeablePolygon.locations[0];

        // Sort writeable polygon location polygons
        writeablePolygonLocation.polygons =
          writeablePolygonLocation.polygons.sort((a, b) => {
            // If b id is polygon id
            if (a.id === polygon.id && b.id !== polygon.id) return -1;

            return 1;
          });

        // Set selected location
        setSelectedLocation(writeablePolygonLocation);

        // Clear selected location marker
        showMarker && clearSelectedLocationMarker();

        // Set selected location marker
        showMarker && setSelectedLocationMarker(
          createLocationMarker(writeablePolygonLocation)
        );
      } else {
        // Set selected location
        setSelectedLocation(undefined);
      }
    }
  );

  // Highlight all the polygons of the selected location
  useEffect(() => {
    if (mapView == null) return;

    // Clear any existing highlights
    mapView.clearAllPolygonColors();

    if (selectedLocation != null) {
      const polygons = selectedLocation.polygons;

      // Highlight each of its polygons
      polygons
        .filter(({ map }) => venue?.maps.map(({ id }) => id).includes(map.id))
        .forEach((polygon) => {
          mapView.setPolygonColor(polygon, selectedLocationColor ?? "red");
        });
    }
  }, [selectedLocation, mapView, selectedLocationColor, venue?.maps]);

  // Return the selected location and a setter
  return { selectedLocation, setSelectedLocation };
}
