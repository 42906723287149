import { Badge, Box, Text, useColorModeValue } from "@chakra-ui/react";
import { TMappedinDirective } from "@mappedin/mappedin-js";

type DirectionsInstructionsHeaderProps = {
  instructions?: TMappedinDirective[];
};

const DirectionsInstructionsHeader = ({
  instructions,
}: DirectionsInstructionsHeaderProps) => {
  return (
    <Box
      backgroundColor={useColorModeValue("gray.100", "gray.600")}
      paddingX={[4, 4, 6]}
      paddingY={[2, 2, 4]}
      top={0}
    >
      <Text fontSize="sm" fontWeight="bold">
        Steps{" "}
        <Badge
          position="relative"
          mb={0.5}
          ml={2}
          borderRadius={99}
          colorScheme="brandPrimary"
          paddingX={2.5}
          paddingY={0.5}
        >
          {instructions?.length}
        </Badge>
      </Text>
    </Box>
  );
};

export default DirectionsInstructionsHeader;
