const WithLoadingLoading = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "white",
        color: "black",
        position: "fixed",
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        zIndex: 9999,
      }}
    >
      <p>Loading...</p>
    </div>
  );
};

export default WithLoadingLoading;
