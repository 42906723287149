import { useMemo } from "react";
import Fuse from "fuse.js";

interface IUseSearchProps {
  dataSet: any[];
  keys: string[];
  query: string;
}

const SCORE_THRESHOLD = 0.4;

export default function useFuseSearch({
  dataSet,
  keys,
  query,
}: IUseSearchProps) {
  const fuse = useMemo(() => {
    const options = {
      includeScore: true,
      location: 0,
      threshold: 0.6,
      distance: 100,
      ignoreFieldNorm: true,
      ignoreLocation: true,
      keys,
    };

    return new Fuse(dataSet, options);
  }, [dataSet, keys]);

  const results = useMemo(() => {
    if (!query) return [];

    const searchResults = fuse.search(query);

    return searchResults
      .filter((fuseResult) => fuseResult.score ?? 0 < SCORE_THRESHOLD)
      .map((fuseResult) => fuseResult.item);
  }, [fuse, query]);

  return {
    query,
    results,
  };
}
