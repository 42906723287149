import { useEffect } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { useGlobalState } from "./context/GlobalStateProvider";
import "@mappedin/mappedin-js/lib/mappedin.css";
import useVenue from "./hooks/useVenue";
import useStartLocation from "./hooks/useStartLocation";
import useDefaultMapRotation from "./hooks/useDefaultMapRotation";
import useDefaultMapTilt from "./hooks/useDefaultMapTilt";
import { TGetVenueOptions } from "@mappedin/mappedin-js";
import Directions from "./components/Directions/Directions";
import ProtectedRoutes from "./components/ProtectedRoutes/ProtectedRoutes";
import Setup from "./components/Setup/Setup";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Main from "./components/Main/Main";
import Error from "./components/Error/Error";
import Start from "./components/Start/Start";
import SearchMILocations from "./components/SearchMILocations/SearchMILocations";
import { Helmet } from "react-helmet-async";
import SearchJsonData from "./components/SearchJsonData/SearchJsonData";
import getScheduleData from "./api/getScheduleData";
import appConfig from "./config/appConfig";
import NewExhibitors from "./components/NewExhibitors/NewExhibitors";
import PubCrawl from "./components/PubCrawl/PubCrawl";
import withLoading, {
  WithLoadingProps,
} from "./components/WithLoading/WithLoading";
import Loading from "./components/Loading/Loading";

export const venueOptions: TGetVenueOptions = {
  venue: "moscone-center",
  clientId: "63e3da5e028a2f001dcb363e",
  clientSecret: "14LyU87ALRGoexbnwwElKWPBl5r6IbzbJ6FODsr8CvhmqG2a",
};

const App = ({ setIsLoading }: WithLoadingProps) => {
  // Global context
  const {
    setState,
    state: { scheduleDataLastUpdate, venueLastUpdate },
  } = useGlobalState();

  // Navigate
  const navigate = useNavigate();

  // Venue
  const venue = useVenue(venueOptions, venueLastUpdate?.requested);

  // Start location
  const startLocation = useStartLocation(venue?.locations);

  // Default map rotation
  const defaultMapRotation = useDefaultMapRotation();

  // Default map tilt
  const defaultMapTilt = useDefaultMapTilt();

  // Hook
  useEffect(() => {
    // If venue null or undefined
    if (venue == null) return;

    // Set is loading
    setIsLoading(false);

    return () => {
      // Set is loading
      setIsLoading(true);
    };
  }, [navigate, setIsLoading, venue]);

  // Hook on copmponent mount
  useEffect(() => {
    // Define now
    const now = Date.now();

    // Set venue last update requested
    setState((prev) => ({
      ...prev,
      venueLastUpdate: {
        ...prev.venueLastUpdate,
        requested: now,
      },
    }));
  }, [setState]);

  // Hook on component mount
  useEffect(() => {
    // Define now
    const now = Date.now();

    // Set schedule data last update requested
    setState((prev) => ({
      ...prev,
      scheduleDataLastUpdate: {
        ...prev.scheduleDataLastUpdate,
        requested: now,
      },
    }));
  }, [setState]);

  // Hook on venue
  useEffect(() => {
    // If venue null or undefined
    if (venue == null) return;

    // Define venue locations
    venue.locations = venue.locations
      .filter((location) => location.polygons.length)
      .filter((location) => !location.name.includes("*Demo"));

    // If locations expo only
    if (appConfig.locationsExpoOnly) {
      // Define expo map id
      const expoMapId = "63da83e92334117691498aa4";

      // Define venue locations
      venue.locations = venue.locations.filter((location) => {
        // Location polygons
        const locationPolygonsInExpo = location.polygons.filter((polygon) => {
          return polygon.map.id === expoMapId;
        });

        // If location polygons in expo length > 0
        if (locationPolygonsInExpo.length > 0) {
          // Define location polygons
          location.polygons = locationPolygonsInExpo;

          return true;
        }

        return false;
      });

      // Define venue maps
      venue.maps = venue.maps.map((map) => {
        // If is expo map
        if (map.id !== expoMapId) {
          map.shortName = "hidden";
        }

        return map;
      });

      // Define venue polygons
      venue.polygons = venue.polygons.filter(({ map }) => map.id === expoMapId);

      // Define venue nodes
      venue.nodes = venue.nodes.filter(({ map }) => map.id === expoMapId);
    }

    // Set venue and venue last updated completed
    setState((prev) => ({
      ...prev,
      venue: venue,
      venueLastUpdate: {
        ...prev.venueLastUpdate,
        completed: Date.now(),
      },
    }));

    return () => {
      // Set venue
      setState((prev) => ({ ...prev, venue: undefined }));
    };
  }, [venue, setState]);

  // Hook
  useEffect(() => {
    // If start location null or undefined
    if (startLocation == null) return;

    // Set start location
    setState((prev) => ({ ...prev, startLocation: startLocation }));

    return () => {
      // Set start location
      setState((prev) => ({ ...prev, startLocation: undefined }));
    };
  }, [startLocation, setState]);

  // Hook
  useEffect(() => {
    // If default map rotation null or undefined
    if (defaultMapRotation == null) return;

    // Set default map rotation
    setState((prev) => ({ ...prev, defaultMapRotation: defaultMapRotation }));

    return () => {
      // Set default map rotation
      setState((prev) => ({ ...prev, defaultMapRotation: 0 }));
    };
  }, [defaultMapRotation, setState]);

  // Hook
  useEffect(() => {
    // If default map tilt null or undefined
    if (defaultMapTilt == null) return;

    // Set default map tilt
    setState((prev) => ({ ...prev, defaultMapTilt: defaultMapTilt }));

    return () => {
      // Set default map tilt
      setState((prev) => ({ ...prev, defaultMapTilt: 0 }));
    };
  }, [defaultMapTilt, setState]);

  // Hook
  useEffect(() => {
    // Get schedule data
    getScheduleData(scheduleDataLastUpdate?.requested ?? Date.now()).then(
      (data) => {
        // If data null or undefined
        if (data == null) {
          // Log error
          console.log("getScheduleData returned no results");
        }

        // Set schedule data and schedule data last update
        setState((prev) => ({
          ...prev,
          scheduleData: data,
          scheduleDataLastUpdate: {
            ...prev.scheduleDataLastUpdate,
            completed: Date.now(),
          },
        }));
      }
    );

    return () => {
      // Set schedule data
      setState((prev) => ({ ...prev, scheduleData: undefined }));
    };
  }, [scheduleDataLastUpdate?.requested, setState]);

  return (
    <>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <title>Event Navigation | 2023 USA | RSA Conference</title>
      </Helmet>
      <Header />
      <Main>
        <Routes>
          {/* Protected routes requiring startLocation to be set in global context */}
          <Route element={<ProtectedRoutes fallbackRoute="/setup" />}>
            <Route
              path="/directions/:destinationLocationId/:destinationPolygonId"
              element={
                appConfig.disableDirections ? (
                  <Navigate to="/error" replace />
                ) : (
                  <Directions />
                )
              }
            />
            <Route
              path="/directions/:destinationLocationId"
              element={
                appConfig.disableDirections ? (
                  <Navigate to="/error" replace />
                ) : (
                  <Directions />
                )
              }
            />
            <Route
              path="/locations"
              element={
                appConfig.disableLocations ? (
                  <Navigate to="/error" replace />
                ) : (
                  <SearchMILocations />
                )
              }
            />
            <Route
              path="/early-stage-expo"
              element={
                appConfig.disableNewExhibitors ? (
                  <Navigate to="/error" replace />
                ) : (
                  <NewExhibitors />
                )
              }
            />
            <Route
              path="/pub-crawl"
              element={
                appConfig.disablePubCrawl ? (
                  <Navigate to="/error" replace />
                ) : (
                  <PubCrawl />
                )
              }
            />
            <Route
              path="/schedule"
              element={
                appConfig.disableSchedule ? (
                  <Navigate to="/error" replace />
                ) : (
                  <SearchJsonData />
                )
              }
            />
            <Route
              path="/start"
              element={
                appConfig.disableStart ? (
                  <Navigate to="/error" replace />
                ) : (
                  <Start />
                )
              }
            />
          </Route>
          {/* Public routes */}
          <Route path="/setup" element={<Setup />} />
          <Route path="/" element={<Navigate to="/start" replace />} />
          <Route path="*" element={<Error />} />
        </Routes>
      </Main>
      <Footer />
    </>
  );
};

export default withLoading(App, <Loading message="Loading app" />);
