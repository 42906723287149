import NavigationButton from "./NavigationButton";
import { Icon, Text, VStack } from "@chakra-ui/react";
import { TbBuildingStore, TbMapSearch } from "react-icons/tb";
import { NavigationState } from "./Navigation";
import appConfig from "../../config/appConfig";

type NavigationLocationsButtonProps = {
  navigationState?: NavigationState;
};

const NavigationLocationsButton = ({
  navigationState,
}: NavigationLocationsButtonProps) => {
  return (
    <NavigationButton
      className="NavigationLocationsButton"
      isDisabled={navigationState === NavigationState.LOCATIONS ? true : false}
      to="/locations"
    >
      <VStack justifyContent="center" spacing={1} width="100%">
        <Icon
          as={appConfig.locationsExpoOnly ? TbBuildingStore : TbMapSearch}
          boxSize={6}
        />
        <Text fontSize="0.75rem" fontWeight={100} textTransform="uppercase">
          {appConfig.locationsExpoOnly ? "Booths" : "Locations"}
        </Text>
      </VStack>
    </NavigationButton>
  );
};

export default NavigationLocationsButton;
