import { useEffect, useState } from "react";
import { MappedinLocation } from "@mappedin/mappedin-js";

const useLocationById = (
  locations: MappedinLocation[] | undefined,
  id: string | undefined
) => {
  // State
  const [location, setLocation] = useState<MappedinLocation>();

  // Hook
  useEffect(() => {
    // If locations is null or undefined
    if (locations == null) return;

    // If id is null or undefined
    if (id == null) return;

    // Define location
    const _location = locations?.find((location) => location.id === id);

    // If location is null or undefined
    if (_location == null) return;

    // Set location
    setLocation(_location);

    return () => {};
  }, [id, locations]);

  return location;
};

export default useLocationById;
