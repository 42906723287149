import { Box, Center, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import { useCallback, useEffect, useRef, useState } from "react";
import Loading from "../Loading/Loading";
import withLoading, { WithLoadingProps } from "../WithLoading/WithLoading";
import useFuseSearch from "./../../hooks/useFuseSearch";
import SearchJsonDataInput from "./SearchJsonDataInput";
import SearchJsonDataResults from "./SearchJsonDataResults";
import SearchJsonDataLoading from "./SearchJsonDataLoading";
import SearchJsonDataNoResults from "./SearchJsonDataNoResults";
import { isBefore } from "date-fns";
import SearchJsonDataLoadMoreResultsButton from "./SearchJsonDataLoadMoreResultsButton";
import { useGlobalState } from "../../context/GlobalStateProvider";
import withIdleTimeout from "../WithIdleTimeout/WithIdleTimeout";

const dataKeys = ["session_abbreviation", "sessiontitle"];

enum SearchState {
  LOADING = "LOADING",
  NO_RESULTS = "NO_RESULTS",
  NONE = "NONE",
  RESULTS = "RESULTS_FOUND",
}

type SearchJsonDataProps = WithLoadingProps & {};

const SearchJsonData = ({ setIsLoading }: SearchJsonDataProps) => {
  // Global context
  const {
    state: { scheduleData: data },
  } = useGlobalState();

  // State
  const [searchDate, setSearchDate] = useState<Date>(new Date());
  const [inputFocused, setInputFocused] = useState<boolean>(false);
  const [numResultsToShow, setNumResultsToShow] = useState(10);
  const [searchData, setSearchData] = useState<any[]>();
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [searchState, setSearchState] = useState<SearchState>(SearchState.NONE);

  // Ref
  const resultsContainer = useRef<HTMLDivElement>(null);

  // Use Fuse search
  const response = useFuseSearch({
    dataSet: searchData ?? [],
    keys: dataKeys,
    query: searchQuery,
  });

  // Input blur callback
  const inputBlurCallback = useCallback(
    (inputValue: string) => {
      // If input focused
      if (inputFocused) {
        // Set input focused
        setInputFocused(false);
      }
    },
    [inputFocused]
  );

  // Input change callback
  const inputChangeCallback = useCallback((inputValue: string) => {
    // Set search value
    setSearchQuery(inputValue);
  }, []);

  // Input focus callback
  const inputFocusCallback = useCallback((inputValue: string) => {
    // Set input focused
    setInputFocused(true);
  }, []);

  // Load more results button callback
  const loadMoreResultsButtonCallback = useCallback(() => {
    // Set number of results to show
    setNumResultsToShow((prev) => prev + 10);
  }, []);

  // Hook
  useEffect(() => {
    // If data is null or undefined
    if (data == null) return;

    // Set is loading
    setIsLoading(false);
  }, [data, setIsLoading]);

  // Hook
  useEffect(() => {
    // Set search date
    setSearchDate(new Date());

    // Set number of results to show
    setNumResultsToShow(10);

    // Set results container current
    const resultsContainerCurrent = resultsContainer.current;

    // Scroll results container current to zero
    resultsContainerCurrent && (resultsContainerCurrent.scrollTop = 0);
  }, [response.query]);

  // Hook
  useEffect(() => {
    // If search query does not match response query
    if (searchQuery && searchQuery !== response.query) {
      // Set search state
      setSearchState(SearchState.LOADING);
    }

    return () => {
      // Set search state
      setSearchState(SearchState.NONE);
    };
  }, [response.query, searchQuery]);

  // Hook
  useEffect(() => {
    // If response results length is zero and response query length is not zero
    if (response.results.length === 0 && response.query.length !== 0) {
      // Set search state
      setSearchState(SearchState.NO_RESULTS);
    }

    return () => {
      // Set search state
      setSearchState(SearchState.NONE);
    };
  }, [response.query.length, response.results.length]);

  // Hook
  useEffect(() => {
    // If response results length greater than zero
    if (response.results.length > 0) {
      // Set search state
      setSearchState(SearchState.RESULTS);
    }
  }, [response.results]);

  // Hook
  useEffect(() => {
    // Filter data
    const filteredData = data?.filter((item) => {
      // Define now
      const now = Date.now();

      // Define end time
      const endTime =
        Date.parse(item.endtime + " GMT") +
        new Date().getTimezoneOffset() * 60 * 1000;

      // If end time is null or undefined
      if (endTime == null) return false;

      // If now is greater than end time
      if (now > endTime) return false;

      return true;
    });

    // Set search data
    setSearchData(filteredData);
  }, [data]);

  return (
    <Flex
      className="SearchJsonData"
      direction="column"
      height="100%"
      overflow="hidden"
    >
      <Box
        backgroundColor="brandPrimary.500"
        className="SearchJsonDataInputWrapper"
        paddingX={[4, 4, 6]}
        paddingY={[2, 2, 4]}
      >
        <SearchJsonDataInput
          blurCallback={inputBlurCallback}
          changeCallback={inputChangeCallback}
          focusCallback={inputFocusCallback}
        />
      </Box>
      <Flex
        backgroundColor={useColorModeValue("gray.100", "gray.800")}
        className="SearchJsonDataResultsContainer"
        flexDirection="column"
        flexGrow={1}
        minHeight={0}
        overflowY="scroll"
        ref={resultsContainer}
      >
        {searchState === SearchState.LOADING && <SearchJsonDataLoading />}
        {searchState === SearchState.NO_RESULTS && <SearchJsonDataNoResults />}
        {searchState === SearchState.RESULTS && (
          <>
            <SearchJsonDataResults
              results={response.results
                .filter((result) =>
                  isBefore(searchDate, new Date(result.endtime))
                )
                .slice(
                  0,
                  response.results.length > numResultsToShow - 1
                    ? numResultsToShow - 1
                    : response.results.length
                )}
            />
            <Box flexShrink={0} paddingBottom={[2, 2, 4]} paddingX={[4, 4, 6]}>
              {response.results.length > numResultsToShow - 1 ? (
                <SearchJsonDataLoadMoreResultsButton
                  callback={loadMoreResultsButtonCallback}
                />
              ) : (
                <Text opacity={0.5} textAlign="center">
                  No more results to show
                </Text>
              )}
            </Box>
          </>
        )}
        {searchState === SearchState.NONE && (
          <Center height="100%" width="100%">
            <Text
              paddingX={[4, 4, 6]}
              opacity={0.5}
              paddingY={[2, 2, 4]}
              textAlign="center"
            >
              Type something in the search field to generate results
            </Text>
          </Center>
        )}
      </Flex>
    </Flex>
  );
};

export default withIdleTimeout(
  withLoading(SearchJsonData, <Loading message="Loading search" />)
);
