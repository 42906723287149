import { ReactNode } from "react";
import { Button, ButtonProps, useColorModeValue } from "@chakra-ui/react";
import { Link as RouterLink, To } from "react-router-dom";

type NavigationButtonProps = ButtonProps & {
  children: ReactNode;
  className: string;
  state?: any;
  to: To;
};

const NavigationButton = ({
  children,
  className,
  isDisabled,
  state,
  to,
}: NavigationButtonProps) => {
  // Button color scheme
  const buttonColorScheme = useColorModeValue("black", "gray");

  // Button color variant
  const buttonColorVariant = useColorModeValue("solid", "ghost");

  return isDisabled ? (
    <Button
      as="a"
      borderRadius={0}
      className={className}
      colorScheme={buttonColorScheme}
      variant={buttonColorVariant}
      height="100%"
      isDisabled={isDisabled}
      paddingX={[4, 4, 6]}
    >
      {children}
    </Button>
  ) : (
    <Button
      as={RouterLink}
      borderRadius={0}
      className={className}
      colorScheme={buttonColorScheme}
      variant={buttonColorVariant}
      height="100%"
      paddingX={[4, 4, 6]}
      state={state}
      to={to}
    >
      {children}
    </Button>
  );
};

export default NavigationButton;
