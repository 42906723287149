import { useEffect, useState } from "react";
import { TbArrowLeft } from "react-icons/tb";
import { Link as RouterLink, useLocation } from "react-router-dom";
import appConfig from "../../config/appConfig";
import { NavigationState } from "./Navigation";
import NavigationButton from "./NavigationButton";
import { Icon, Text } from "@chakra-ui/react";

type NavigationBackButtonProps = {
  navigationState?: NavigationState;
};

const NavigationBackButton = ({
  navigationState,
}: NavigationBackButtonProps) => {
  // State
  const [backPath, setBackPath] = useState<string>();

  // Location
  const { state: locationState } = useLocation();

  // Hook
  useEffect(() => {
    // If location state null or undefined
    if (locationState == null) {
      // Switch on navigation state
      switch (navigationState) {
        case NavigationState.DIRECTIONS:
          // Set backpath
          !appConfig.disableStart && setBackPath("/start");
          break;
        case NavigationState.LOCATIONS:
          // Set backpath
          !appConfig.disableStart && setBackPath("/start");
          break;
        case NavigationState.PUB_CRAWL:
          // Set backpath
          !appConfig.disableStart && setBackPath("/start");
          break;
        case NavigationState.NEW_EXHIBITORS:
          // Set backpath
          !appConfig.disableStart && setBackPath("/start");
          break;
        case NavigationState.SCHEDULE:
          // Set backpath
          !appConfig.disableStart && setBackPath("/start");
          break;
        default:
          // Set backpath
          setBackPath(undefined);
      }

      return;
    }

    // If location state from
    if (locationState.from) {
      // Set back path
      setBackPath(locationState.from);

      return;
    }

    return () => {
      // Set back path
      setBackPath(undefined);
    };
  }, [locationState, navigationState]);

  return backPath ? (
    <NavigationButton
      as={RouterLink}
      className="NavigationButton"
      to={backPath ?? "#"}
    >
      <Icon as={TbArrowLeft} boxSize={6} marginRight={2} />
      <Text as="span" fontSize="md" textTransform="uppercase">
        Back
      </Text>
    </NavigationButton>
  ) : (
    <></>
  );
};

export default NavigationBackButton;
