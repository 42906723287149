import { Alert, AlertIcon } from "@chakra-ui/react";

const SearchMILocationsNoResults = () => {
  return (
    <Alert
      backgroundColor="transparent"
      className="SearchMILocationsNoResults"
      status="warning"
    >
      <AlertIcon />
      No results matched your search
    </Alert>
  );
};

export default SearchMILocationsNoResults;
