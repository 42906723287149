import { MappedinLocation, MapView } from "@mappedin/mappedin-js";
import { MouseEvent, ReactNode, useEffect, useState } from "react";
import useCameraTransformedByUser from "../../../hooks/useCameraTransformedByUser";
import { mapViewCameraFocusOnOptions } from "../MIMapView";
import MIMapViewResetDefault from "./MIMapViewResetDefault";

type MIMapViewResetProps = {
  component?: ReactNode;
  defaultMapRotation?: number;
  defaultMapTilt?: number;
  mapView?: MapView;
  startLocation?: MappedinLocation;
};

const MIMapViewReset = ({
  component,
  defaultMapRotation,
  defaultMapTilt,
  mapView,
}: MIMapViewResetProps) => {
  // State
  const [enabled, setEnabled] = useState<boolean>(false);

  // Use camera transformed by user
  const { reset, cameraTransformedByUser } = useCameraTransformedByUser(
    mapView,
    () => {}
  );

  // Handle click
  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    // If current map path
    if (mapView?.currentPath) {
      // Focus map view camera
      mapView?.Camera.focusOn(
        {
          nodes: mapView?.currentPath.filter(
            (node) => node.map.id === mapView?.currentMap.id
          ),
        },
        {
          minZoom: 1500,
          rotation: defaultMapRotation,
          tilt: defaultMapTilt,
        }
      );
    } else {
      // Focus map view camera
      mapView?.Camera.focusOn(
        {
          polygons: mapView?.currentMap.polygons,
        },
        {
          ...mapViewCameraFocusOnOptions,
          rotation: defaultMapRotation,
          tilt: defaultMapTilt,
        }
      );
    }

    // Reset camera transformed by user
    reset();
  };

  // Hook
  useEffect(() => {
    // If camera transformed by user
    if (cameraTransformedByUser) {
      // Set enabled
      setEnabled(true);
    } else {
      // Set enabled
      setEnabled(false);
    }

    return () => {
      // Set enabled
      setEnabled(false);
    };
  }, [cameraTransformedByUser]);

  return (
    <div
      onClick={handleClick}
      style={{
        cursor: "pointer",
        opacity: enabled ? 1 : 0.5,
        pointerEvents: enabled ? "all" : "none",
      }}
    >
      {component ? component : <MIMapViewResetDefault />}
    </div>
  );
};

export default MIMapViewReset;
