import { QRCodeSVG } from "qrcode.react";

type DirectionsQRCodeProps = {
  value?: string;
};

const DirectionsQRCode = ({ value }: DirectionsQRCodeProps) => {
  return value ? (
    <QRCodeSVG
      size={64}
      style={{ height: "auto", width: "100%" }}
      value={value}
    />
  ) : (
    <></>
  );
};

export default DirectionsQRCode;
