import {
  Box,
  CloseButton,
  Flex,
  Collapse,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { Dispatch, RefObject, SetStateAction, useEffect } from "react";
import SimpleKeyboardWrapper from "./SimpleKeyboardWrapper";
import "./Keyboard.css";

type KeyboardProps = {
  keyboardRef: RefObject<any>;
  setShowKeyboard: Dispatch<SetStateAction<boolean>>;
  setTargetInputValue: Dispatch<SetStateAction<string>>;
  showKeyboard: boolean;
};

const Keyboard = ({
  keyboardRef,
  setShowKeyboard,
  setTargetInputValue,
  showKeyboard,
}: KeyboardProps) => {
  // Disclosure
  const { isOpen, onOpen, onClose } = useDisclosure();

  // Handle close button
  const handleCloseButton = () => {
    // If show keyboard
    if (showKeyboard) {
      // Set show keyboard
      setShowKeyboard(false);
    }
  };

  // Hook: showKeyboard
  useEffect(() => {
    // If true
    if (showKeyboard) {
      // Open slide
      onOpen();
    } else {
      // Close slide
      onClose();
    }

    return () => {
      // Close slide
      onClose();
    };
  }, [onClose, onOpen, showKeyboard]);

  return (
    <Box
      className="Keyboard"
      position="fixed"
      left={0}
      bottom={[16, 16, 20]}
      right={0}
      zIndex={9999999}
    >
      <Collapse in={isOpen} style={{ zIndex: 9999999 }}>
        <VStack
          bg="gray.300"
          className="VStack"
          roundedTop="md"
          shadow="md"
          spacing={0}
        >
          <Flex justifyContent="end" width="100%">
            <CloseButton onClick={handleCloseButton} />
          </Flex>
          <Box py={2} width={["100%", "100%", "75%", "50%", "50%"]}>
            <SimpleKeyboardWrapper
              keyboardRef={keyboardRef}
              setTargetInputValue={setTargetInputValue}
            />
          </Box>
        </VStack>
      </Collapse>
    </Box>
  );
};

export default Keyboard;
