import { Box, Grid, GridItem, HStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import appConfig from "../../config/appConfig";
import NavigationBackButton from "./NavigationBackButton";
import NavigationHomeButton from "./NavigationHomeButton";
import NavigationLocationsButton from "./NavigationLocationsButton";
import NavigationNewExhibitorsButton from "./NavigationNewExhibitorsButton";
import NavigationPubCrawlButton from "./NavigationPubCrawlButton";
import NavigationScheduleButton from "./NavigationScheduleButton";

export enum NavigationState {
  DIRECTIONS = "DIRECTIONS",
  LOCATIONS = "LOCATIONS",
  NEW_EXHIBITORS = "NEW_EXHIBITORS",
  PUB_CRAWL = "PUB_CRAWL",
  SCHEDULE = "SCHEDULE",
  SETUP = "SETUP",
  START = "START",
}

const Navigation = () => {
  // State
  const [navigationState, setNavigationState] = useState<NavigationState>();

  // Location
  const { pathname: locationPathName } = useLocation();

  // Hook
  useEffect(() => {
    // If location null or undefined
    if (locationPathName == null) return;

    // If location path name contains /directions
    if (locationPathName.includes("/directions/")) {
      // Set navigation state
      setNavigationState(NavigationState.DIRECTIONS);
    }

    // If location path name contains /locations
    if (locationPathName.includes("/locations")) {
      // Set navigation state
      setNavigationState(NavigationState.LOCATIONS);
    }

    // If location path name contains /early-stage-expo
    if (locationPathName.includes("/early-stage-expo")) {
      // Set navigation state
      setNavigationState(NavigationState.NEW_EXHIBITORS);
    }

    // If location path name contains /pub-crawl
    if (locationPathName.includes("/pub-crawl")) {
      // Set navigation state
      setNavigationState(NavigationState.PUB_CRAWL);
    }

    // If location path name contains /schedule
    if (locationPathName.includes("/schedule")) {
      // Set navigation state
      setNavigationState(NavigationState.SCHEDULE);
    }

    // If location path name contains /start
    if (locationPathName.includes("/start")) {
      // Set navigation state
      setNavigationState(NavigationState.START);
    }

    return () => {
      // Set navigation state
      setNavigationState(undefined);
    };
  }, [locationPathName]);

  return (
    <Box className="Navigation" height={[16, 16, 20]} width="100%">
      <Grid
        className="Grid"
        templateColumns="repeat(5, 1fr)"
        height="100%"
        width="100%"
      >
        <GridItem className="GridItem">
          <NavigationBackButton navigationState={navigationState} />
        </GridItem>
        <GridItem className="GridItem" colSpan={3}>
          <HStack
            className="HStack"
            height="100%"
            justifyContent="center"
            spacing={0}
            width="100%"
          >
            {!appConfig.disableStart && (
              <NavigationHomeButton navigationState={navigationState} />
            )}
            {!appConfig.disableLocations && (
              <NavigationLocationsButton navigationState={navigationState} />
            )}
            {!appConfig.disableNewExhibitors && (
              <NavigationNewExhibitorsButton
                navigationState={navigationState}
              />
            )}
            {!appConfig.disablePubCrawl && (
              <NavigationPubCrawlButton navigationState={navigationState} />
            )}
            {!appConfig.disableSchedule && (
              <NavigationScheduleButton navigationState={navigationState} />
            )}
          </HStack>
        </GridItem>
        <GridItem className="GridItem"></GridItem>
      </Grid>
    </Box>
  );
};

export default Navigation;
