import {
  Mappedin,
  OfflineSearch,
  TMappedinOfflineSearchResult,
} from "@mappedin/mappedin-js";
import { useDeferredValue, useEffect, useState } from "react";

type Response = {
  query: string;
  results: TMappedinOfflineSearchResult[];
};

/**
 * Declarative OfflineSearch API that returns a deferred value
 */
export default function useOfflineSearch(
  venue: Mappedin | undefined,
  query: string
) {
  // Store the OfflineSearch instance in a state variable
  const [searchInstance, setSearchInstance] = useState<
    OfflineSearch | undefined
  >();

  // Store the most recent response
  const [response, setResponse] = useState<Response>({
    query: "",
    results: [],
  });

  // Defer the new search query until state updates are complete
  const deferredQuery = useDeferredValue(query);

  // Create the OfflineSearch instance
  useEffect(() => {
    // If venue is null
    if (venue == null) {
      // Set search instance
      setSearchInstance(undefined);

      return;
    }

    // Define offline search instance
    const instance = new OfflineSearch(venue, {
      emitAnalyticsEvents: false,
    });

    // Set search instance
    setSearchInstance(instance);
  }, [venue]);

  // Get search results asynchronously
  useEffect(() => {
    // If venue or search instance or deferred query are null
    if (venue == null || searchInstance == null || deferredQuery === "") {
      // Set response
      setResponse({
        query: deferredQuery,
        results: [],
      });

      return;
    }

    // Define generate results
    const generateSearchResults = async () => {
      // Define results
      const _results = await searchInstance.search(deferredQuery);

      // Set response
      setResponse({ query: deferredQuery, results: _results });
    };

    // Generate search results
    generateSearchResults();
  }, [deferredQuery, venue, searchInstance]);

  // Return the most recent results
  return response;
}
