import React, { MouseEvent, ReactNode } from "react";

type MIMapViewControlLabelProps = {
  children?: ReactNode;
  labelText?: string;
  show?: boolean;
};

const MIMapViewControlLabel = ({
  children,
  labelText,
  show,
}: MIMapViewControlLabelProps) => {
  // Handle click
  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    // Define button child
    const buttonChild = event.currentTarget.querySelector("button");

    // Trigger click on button child
    buttonChild?.click();
  };

  return show ? (
    <div
      className="MIMapViewControlLabel"
      onClick={handleClick}
      style={{
        alignItems: "center",
        backgroundColor: "white",
        borderRadius: "9999px",
        boxShadow: "0 0.125rem 0.25rem rgba(0,0,0,0.25)",
        display: "flex",
        flexDirection: "row",
        padding: "0 0 0 1.125rem",
      }}
    >
      <span
        style={{
          color: "rgba(0,0,0,0.7)",
          display: "block",
          padding: "0 0.625rem 0 0",
        }}
      >
        {labelText}
      </span>
      {children}
    </div>
  ) : (
    <>{children}</>
  );
};

export default MIMapViewControlLabel;
