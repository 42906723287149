import { useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useGlobalState } from "../../context/GlobalStateProvider";

type ProtectedRoutesProps = {
  fallbackRoute?: string;
};

const ProtectedRoutes = ({ fallbackRoute }: ProtectedRoutesProps) => {
  // Global context
  const {
    state: { startLocation },
    setState,
  } = useGlobalState();

  // Define location
  const location = useLocation();

  // Define requested location
  const requestedLocation = location.pathname;

  // Hook
  useEffect(() => {
    // If start location is null or undefined
    if (startLocation == null) {
      // Set redirect after fallback route
      setState((prev) => ({
        ...prev,
        redirectAfterFallbackRoute: requestedLocation,
      }));
    }
  }, [requestedLocation, setState, startLocation]);

  return startLocation ? (
    <Outlet />
  ) : (
    <Navigate
      to={{
        pathname: fallbackRoute ?? "/",
        search: location.search,
      }}
      replace
    />
  );
};

export default ProtectedRoutes;
