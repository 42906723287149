import {
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Switch,
  useColorModeValue,
} from "@chakra-ui/react";
import { ChangeEvent } from "react";
import { TbAccessible } from "react-icons/tb";

type DirectionsAccessibleRoutesSwitchProps = {
  accessibleRoutesOnly?: boolean;
  changeCallback?: (switchValue: boolean) => void;
};

const DirectionsAccessibleRoutesSwitch = ({
  accessibleRoutesOnly,
  changeCallback,
}: DirectionsAccessibleRoutesSwitchProps) => {
  // Handle change
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    // Call change callback
    changeCallback && changeCallback(event.target.checked);
  };

  return (
    <Flex
      backgroundColor={useColorModeValue("gray.200", "gray.600")}
      justifyContent="center"
      paddingX={[4, 4, 6]}
      paddingY={[2, 2, 4]}
    >
      <FormControl display="flex" alignItems="center" width="auto">
        <HStack spacing={4}>
          <Switch
            id="toggle-accessible-routes"
            checked={!!accessibleRoutesOnly}
            colorScheme="brandPrimary"
            isChecked={!!accessibleRoutesOnly}
            onChange={handleChange}
          />
          <HStack>
            <Icon as={TbAccessible} color="brandPrimary.800" />
            <FormLabel
              color="brandPrimary.800"
              fontSize={["smaller", "md"]}
              htmlFor="toggle-accessible-routes"
              mb="0"
              paddingRight={2}
            >
              Show accessible routes only
            </FormLabel>
          </HStack>
        </HStack>
      </FormControl>
    </Flex>
  );
};

export default DirectionsAccessibleRoutesSwitch;
