import { Box, useColorModeValue } from "@chakra-ui/react";
import { MapView } from "@mappedin/mappedin-js";
import React, { useEffect, useRef, useState } from "react";

type MapWrapperProps = {
  children: React.ReactElement | React.ReactElement[];
  mapView: MapView | undefined;
};

const MapWrapper = ({ children, mapView }: MapWrapperProps) => {
  // Ref
  const observedDiv = useRef<HTMLDivElement>(null);
  const resizeTimerRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const setDimensionsTimerRef = useRef<ReturnType<typeof setTimeout> | null>(
    null
  );

  // State
  const [height, setHeight] = useState<number>();
  const [mapHeightPx, setMapHeightPx] = useState<string>();
  const [mapWidthPx, setMapWidthPx] = useState<string>();
  const [width, setWidth] = useState<number>();
  const [resizing, setResizing] = useState<boolean>(false);
  
  // Handle element resized
  const handleElementResized = () => {
    // If observed height or observed with are not defined
    if (
      !observedDiv?.current?.clientHeight ||
      !observedDiv?.current?.clientWidth
    ) {
      return;
    }

    // Set height
    setHeight(observedDiv?.current?.clientHeight);

    // Set width
    setWidth(observedDiv?.current?.clientWidth);

    // If observed height is not equal to stored height or observed width is not equal to stored width
    if (
      (observedDiv?.current?.clientHeight !== height ||
        observedDiv?.current?.clientWidth !== width) &&
      (typeof height !== "undefined" || typeof width !== "undefined")
    ) {
      // If not resizing
      if (!resizing) {
        // Set resizing
        setResizing(true);
      }
    } else {
      // Clear timeout
      clearTimeout(resizeTimerRef.current ?? undefined);

      resizeTimerRef.current = setTimeout(() => {
        // Set resizing
        setResizing(false);
      }, 50);
    }
  };

  // Resize observer
  const resizeObserver = new ResizeObserver(handleElementResized);

  // Hook on height, width
  useEffect(() => {
    // If not resizing
    if (!resizing) {
      // Clear timeout
      clearTimeout(setDimensionsTimerRef.current ?? undefined);

      setDimensionsTimerRef.current = setTimeout(() => {
        // Set map height in px
        setMapHeightPx(height + "px");

        // Set map width in px
        setMapWidthPx(width + "px");
      }, 0);
    }
  }, [height, width, resizing]);

  // Hook on component mount
  useEffect(() => {
    // Attach observer to element
    resizeObserver.observe(observedDiv.current as Element);

    return function cleanup() {
      // Disconnect resize observer
      resizeObserver.disconnect();
    };
  });

  // Map children
  const _children = React.Children.map(children, (child, index) => {
    return React.cloneElement(child, {
      height: mapHeightPx,
      resizing: resizing,
      width: mapWidthPx,
    });
  });

  return (
    <Box
      backgroundColor={useColorModeValue("white", "gray.500")}
      className="MapWrapper"
      height="100%"
      overflow="hidden"
      position="relative"
      ref={observedDiv}
      width="100%"
    >
      {_children}
    </Box>
  );
};

export default MapWrapper;
