import {
  Mappedin,
  MappedinLocation,
  MappedinMap,
  MapView,
} from "@mappedin/mappedin-js";
import { MouseEvent, useEffect, useState } from "react";
import "./MIMapViewMapSelectDefault.css";
import mapMarker from "./../map-marker.svg";

type MIMapViewMapSelectDefaultProps = {
  currentMap?: MappedinMap;
  mapView?: MapView;
  startLocation?: MappedinLocation;
  venue?: Mappedin;
};

const MIMapViewMapSelectDefault = ({
  currentMap,
  mapView,
  startLocation,
  venue,
}: MIMapViewMapSelectDefaultProps) => {
  // State
  const [open, setOpen] = useState<boolean>(false);
  const [selectedMap, setSelectedMap] = useState<MappedinMap>();

  // Handle click
  const handleToggleButtonClick = () => {
    // If open
    if (open) {
      // Set open
      setOpen(false);
    } else {
      // Set open
      setOpen(true);
    }
  };

  // Handle map div click
  const handleOptionClick = (
    event: MouseEvent<HTMLDivElement>,
    map: MappedinMap
  ) => {
    // If open
    if (open) {
      // Set timeout
      setTimeout(() => {
        // Set open
        setOpen(false);
      }, 50);
    } else {
      // Set open
      setOpen(true);
    }

    // Set selected map
    setSelectedMap(map);

    // Set map view map
    mapView?.setMap(map).then(() => {
      // Focus map view
      mapView?.Camera.focusOn(
        {
          polygons: map.polygons,
        },
        {
          duration: 0,
        }
      );
    });
  };

  // Hook
  useEffect(() => {
    // Set selected map
    setSelectedMap(mapView?.currentMap);
  }, [mapView?.currentMap]);

  return (
    <div
      className="MIMapViewMapSelectDefault"
      style={{
        backgroundColor: "white",
        borderRadius: "99rem",
        boxShadow: "0 0.125rem 0.25rem rgba(0,0,0,0.25)",
        height: "3rem",
        position: "relative",
        width: "3rem",
      }}
    >
      <button
        className="ToggleButton"
        onClick={handleToggleButtonClick}
        style={{
          backgroundColor: "white",
          borderRadius: "99rem",
          outline: open ? "0.125rem solid rgba(0,0,0,0.1)" : "none",
          height: "3rem",
          position: "absolute",
          userSelect: "none",
          width: "3rem",
          zIndex: 1,
        }}
      >
        <div
          className="Content"
          style={{
            left: "50%",
            position: "absolute",
            top: "50%",
            transform: "translate(-50%, -50%)",
            userSelect: "none",
          }}
        >
          <div
            style={{
              lineHeight: 1,
              position: "relative",
              textAlign: "center",
              userSelect: "none",
            }}
          >
            <span
              style={{
                color: "black",
                display: "inline-block",
                fontFamily: "Arial, Helvetica, sans-serif",
                fontSize: "1.125rem",
                fontWeight: "bold",
                left: "0.03125rem",
                position: "relative",
                userSelect: "none",
                whiteSpace: "nowrap",
              }}
            >
              {open ? (
                <span
                  style={{
                    fontSize: "1.5rem",
                    fontWeight: "bold",
                  }}
                >
                  &#215;
                </span>
              ) : (
                currentMap?.shortName
              )}
            </span>
          </div>
        </div>
      </button>
      <div
        className="Options"
        style={{
          backgroundColor: "white",
          bottom: "-0.5rem",
          borderBottomRightRadius: "2rem",
          borderBottomLeftRadius: "0.5rem",
          borderTopLeftRadius: "0.5rem",
          borderTopRightRadius: "0.5rem",
          boxShadow: "0 0.125rem 0.25rem rgba(0,0,0,0.25)",
          display: open ? "block" : "none",
          padding: "0.75rem 0",
          position: "absolute",
          right: "-0.5rem",
          zIndex: 0,
        }}
      >
        {venue?.maps
          ?.filter(({ shortName }) => shortName !== "hidden")
          ?.sort((a, b) => {
            return b.elevation - a.elevation;
          })
          .map((map, idx) => (
            <div
              className="Option"
              onClick={(e) => handleOptionClick(e, map)}
              key={idx}
              style={{
                backgroundColor:
                  map.id === selectedMap?.id ? "rgba(0,0,0,0.1)" : undefined,
                display: "flex",
                justifyContent: "space-between",
                padding: "0.25rem 0.75rem",
                userSelect: "none",
                whiteSpace: "nowrap",
              }}
            >
              <div
                style={{
                  alignItems: "start",
                  display: "flex",
                  flexGrow: 1,
                  padding: "0 0.5rem 0 1rem",
                  position: "relative",
                }}
              >
                {startLocation?.polygons[0].map.id === map.id && (
                  <img
                    alt="Map marker"
                    src={mapMarker}
                    style={{
                      position: "absolute",
                      left: "-0.25rem",
                      top: "0.25rem",
                      width: "1rem",
                    }}
                  />
                )}{" "}
                <div>{map.name}</div>
              </div>
              <div
                style={{
                  fontWeight: "bold",
                  padding: "0 0.5rem",
                  userSelect: "none",
                }}
              >
                {map.shortName}
              </div>
            </div>
          ))}
        <div style={{ padding: "1.75rem" }}></div>
      </div>
    </div>
  );
};

export default MIMapViewMapSelectDefault;
