import { Box, Button } from "@chakra-ui/react";
import { ReactNode } from "react";
import { Link as RouterLink, LinkProps, To } from "react-router-dom";

type StartSquareButtonProps = {
  children?: ReactNode;
  state: LinkProps["state"];
  to: To;
};

const StartSquareButton = ({ children, state, to }: StartSquareButtonProps) => {
  return (
    <Button
      _before={{ content: `""`, display: "block", paddingTop: "100%" }}
      as={RouterLink}
      borderRadius={0}
      className="StartSquareButton"
      colorScheme="brandPrimary"
      display="block"
      float="left"
      height="auto"
      padding={0}
      position="relative"
      state={state}
      to={to}
      width="100%"
    >
      <Box
        as="span"
        left="50%"
        padding={[4, 4, 6]}
        position="absolute"
        textAlign="center"
        top="50%"
        transform="translate(-50%, -50%)"
        width="100%"
      >
        {children}
      </Box>
    </Button>
  );
};

export default StartSquareButton;
