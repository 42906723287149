import { useEffect, useState } from "react";
import { Mappedin, MapView, showVenue } from "@mappedin/mappedin-js";

const mapViewOptions = {
  backgroundAlpha: 0.000000000001,
  backgroundColor: "#000000",
  disableZoomOnMapSizeChange: true,
};

function useMapView(
  el: HTMLElement | null | undefined,
  venue: Mappedin | undefined
) {
  // Store the MapView instance in a state variable
  const [mapView, setMapView] = useState<MapView | undefined>();

  // Render the MapView asynchronously
  useEffect(() => {
    // Define render venue
    const renderVenue = async () => {
      // Do nothing if the  map container or venue data are not initialized
      if (el == null || venue == null) return;

      // Do nothing if the mapView is already rendered with the current venue data
      if (mapView != null && mapView.venue.venue.id === venue.venue.id) return;

      // If the mapView has been rendered with old data, destroy it
      if (mapView != null) {
        // Destroy map view
        mapView.destroy();
      }

      try {
        // Define map view
        const _mapView = await showVenue(el, venue, mapViewOptions);

        // Set map view
        setMapView(_mapView);
      } catch (e) {
        // Log error
        console.log(e);

        // Set map view
        setMapView(undefined);
      }
    };

    // Call render venue
    renderVenue();
  }, [el, venue, mapView]);

  // Return the MapView instance
  return mapView;
}

export default useMapView;
