import {
  E_SDK_EVENT,
  E_SDK_EVENT_PAYLOAD,
  MapView,
} from "@mappedin/mappedin-js";
import { useCallback, useEffect } from "react";

/**
 * Declarative API to subscribe to an E_SDK_EVENT.CLICK
 */
export default function useMapChanged(
  mapView: MapView | undefined,
  onMapChanged: (payload: E_SDK_EVENT_PAYLOAD[E_SDK_EVENT.MAP_CHANGED]) => void
) {
  // Define handle map changed
  const handleMapChanged = useCallback(
    (payload: E_SDK_EVENT_PAYLOAD[E_SDK_EVENT.MAP_CHANGED]) => {
      // Call on map changed
      onMapChanged(payload);
    },
    [onMapChanged]
  );

  // Subscribe to E_SDK_EVENT.CLICK
  useEffect(() => {
    // If map view is null
    if (mapView == null) return;

    // Bind map changed handler
    mapView.on(E_SDK_EVENT.MAP_CHANGED, handleMapChanged);

    // Cleanup
    return () => {
      // Unbind map changed handler
      mapView.off(E_SDK_EVENT.MAP_CHANGED, handleMapChanged);
    };
  }, [mapView, handleMapChanged]);
}
