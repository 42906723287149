import { Mappedin, MappedinLocation, MapView } from "@mappedin/mappedin-js";
import React, { ReactNode } from "react";
import MIMapViewCompass from "./MIMapViewCompass/MIMapViewCompass";
import MIMapViewMapSelect from "./MIMapViewMapSelect/MIMapViewMapSelect";
import MIMapViewReset from "./MIMapViewReset/MIMapViewReset";
import MIMapViewZoomIn from "./MIMapViewZoomIn/MIMapViewZoomIn";
import MIMapViewZoomOut from "./MIMapViewZoomOut/MIMapViewZoomOut";

export type MIMapViewControlsProps = {
  compass?: boolean;
  compassComponent?: ReactNode;
  defaultMapRotation?: number;
  defaultMapTilt?: number;
  mapSelect?: boolean;
  mapSelectComponent?: ReactNode;
  mapSelectDisabled?: boolean;
  mapSelectShowLabel?: boolean;
  mapView?: MapView;
  refocus?: boolean;
  refocusComponent?: ReactNode;
  spacing?: number;
  startLocation?: MappedinLocation;
  venue?: Mappedin;
  zoomIn?: boolean;
  zoomInComponent?: ReactNode;
  zoomInShowLabel?: boolean;
  zoomOut?: boolean;
  zoomOutComponent?: ReactNode;
  zoomOutShowLabel?: boolean;
};

const MIMapViewControls = ({
  compass = true,
  compassComponent,
  defaultMapRotation,
  defaultMapTilt,
  mapSelect = true,
  mapSelectComponent,
  mapSelectDisabled,
  mapSelectShowLabel,
  mapView,
  refocus = true,
  refocusComponent,
  spacing,
  startLocation,
  venue,
  zoomIn = true,
  zoomInComponent,
  zoomInShowLabel,
  zoomOut = true,
  zoomOutComponent,
  zoomOutShowLabel,
}: MIMapViewControlsProps) => {
  // Define controls array
  const controlsArray = [];

  // Push to controls array
  compass &&
    controlsArray.push(
      <MIMapViewCompass
        component={compassComponent}
        defaultMapRotation={defaultMapRotation}
        mapView={mapView}
      />
    );

  // Push to controls array
  refocus &&
    controlsArray.push(
      <MIMapViewReset
        component={refocusComponent}
        defaultMapRotation={defaultMapRotation}
        defaultMapTilt={defaultMapTilt}
        mapView={mapView}
        startLocation={startLocation}
      />
    );

  // Push to controls array
  zoomIn &&
    controlsArray.push(
      <MIMapViewZoomIn
        component={zoomInComponent}
        mapView={mapView}
        showLabel={zoomInShowLabel}
      />
    );

  // Push to controls array
  zoomOut &&
    controlsArray.push(
      <MIMapViewZoomOut
        component={zoomOutComponent}
        mapView={mapView}
        showLabel={zoomOutShowLabel}
      />
    );

  // Push to controls array
  mapSelect &&
    controlsArray.push(
      <MIMapViewMapSelect
        component={mapSelectComponent}
        disabled={mapSelectDisabled}
        mapView={mapView}
        showLabel={mapSelectShowLabel}
        startLocation={startLocation}
        venue={venue}
      />
    );

  return (
    <div
      className="MIMapViewControls"
      style={{
        alignItems: "end",
        bottom: "1rem",
        display: "flex",
        flexDirection: "column",
        marginBottom: `-${spacing ?? 1 * 0.5}rem`,
        marginTop: `-${spacing ?? 1 * 0.5}rem`,
        pointerEvents: "none",
        position: "absolute",
        right: "1rem",
        userSelect: "none",
        zIndex: 99999,
      }}
    >
      {controlsArray.map((child, index) => {
        return (
          <div
            key={index}
            style={{
              marginBottom: `${spacing ?? 1 * 0.5}rem`,
              marginTop: `${spacing ?? 1 * 0.5}rem`,
              pointerEvents: "all",
            }}
          >
            {child}
          </div>
        );
      })}
    </div>
  );
};

export default MIMapViewControls;
