import { Alert, AlertIcon } from "@chakra-ui/react";

const SearchJsonDataNoResults = () => {
  return (
    <Alert
      className="SearchJsonDataNoResults"
      justifyContent="center"
      status="warning"
      textAlign="center"
      width="100%"
    >
      <AlertIcon />
      No results matched your search
    </Alert>
  );
};

export default SearchJsonDataNoResults;
