import {
  CAMERA_EVENT_PAYLOAD,
  E_CAMERA_EVENT,
  MapView,
} from "@mappedin/mappedin-js";
import { useCallback, useEffect, useState } from "react";

/**
 * Declarative API to subscribe to an E_SDK_EVENT.CLICK
 */
export default function useCameraTransformedByUser(
  mapView: MapView | undefined,
  onCameraTransformedByUser: (
    payload: CAMERA_EVENT_PAYLOAD[E_CAMERA_EVENT.USER_INTERACTION_START]
  ) => void
) {
  // State
  const [lastPositionX, setLastPositionX] = useState<number>();
  const [lastPositionY, setLastPositionY] = useState<number>();
  const [lastRotation, setLastRotation] = useState<number>();
  const [lastTilt, setLastTilt] = useState<number>();
  const [lastZoom, setLastZoom] = useState<number>();
  const [cameraTransformedByUser, setCameraTransformedByUser] =
    useState<boolean>(false);

  // Define handle user interaction start
  const handleUserInteractionStart = useCallback(
    (payload: CAMERA_EVENT_PAYLOAD[E_CAMERA_EVENT.USER_INTERACTION_START]) => {
      // Set last position x
      setLastPositionX(mapView?.Camera.position.x);

      // Set last position y
      setLastPositionY(mapView?.Camera.position.y);

      // Set last rotation
      setLastRotation(mapView?.Camera.rotation);

      // Set last tilt
      setLastTilt(mapView?.Camera.tilt);

      // Set last zoom
      setLastZoom(mapView?.Camera.zoom);
    },
    [
      mapView?.Camera.position,
      mapView?.Camera.rotation,
      mapView?.Camera.tilt,
      mapView?.Camera.zoom,
    ]
  );

  // Define handle user interaction end
  const handleUserInteractionEnd = useCallback(
    (payload: CAMERA_EVENT_PAYLOAD[E_CAMERA_EVENT.USER_INTERACTION_END]) => {
      // If current camera values do not match stored values
      if (
        mapView?.Camera.position.x !== lastPositionX ||
        mapView?.Camera.position.y !== lastPositionY ||
        mapView?.Camera.rotation !== lastRotation ||
        mapView?.Camera.tilt !== lastTilt ||
        mapView?.Camera.zoom !== lastZoom
      ) {
        // Set camera transformed by user
        setCameraTransformedByUser(true);
      }
    },
    [
      lastPositionX,
      lastPositionY,
      lastRotation,
      lastTilt,
      lastZoom,
      mapView?.Camera.position,
      mapView?.Camera.rotation,
      mapView?.Camera.tilt,
      mapView?.Camera.zoom,
    ]
  );

  // Reset
  const reset = () => {
    // Set user panned or zoomed
    setCameraTransformedByUser(false);
  };

  // Subscribe to E_SDK_EVENT.CLICK
  useEffect(() => {
    // If map view is null
    if (mapView == null) return;

    // Bind map view camera handler
    mapView.Camera.on(
      E_CAMERA_EVENT.USER_INTERACTION_START,
      handleUserInteractionStart
    );

    // Bind map view camera handler
    mapView.Camera.on(
      E_CAMERA_EVENT.USER_INTERACTION_END,
      handleUserInteractionEnd
    );

    // Cleanup
    return () => {
      // Unbind map view camera handler
      mapView.Camera.off(
        E_CAMERA_EVENT.USER_INTERACTION_START,
        handleUserInteractionStart
      );

      // Unbind map view camera handler
      mapView.Camera.off(
        E_CAMERA_EVENT.USER_INTERACTION_END,
        handleUserInteractionEnd
      );
    };
  }, [mapView, handleUserInteractionStart, handleUserInteractionEnd]);

  // Hook
  useEffect(() => {
    // If not camera transformed by user
    if (!cameraTransformedByUser) {
      return;
    }

    // Call on camera transformed by user
    onCameraTransformedByUser();
    return () => {};
  }, [cameraTransformedByUser, onCameraTransformedByUser]);
  return { reset, cameraTransformedByUser };
}
