import { Box, Spinner, Text } from "@chakra-ui/react";

const SearchJsonDataLoading = () => {
  return (
    <Box
      className="SearchJsonDataLoading"
      paddingX={4}
      paddingY={2}
      width="100%"
    >
      <Spinner
        color="blue.500"
        emptyColor="gray.200"
        marginRight={2}
        size="xs"
        speed="0.65s"
        thickness="2px"
      />
      <Text display="inline" size="sm">
        Loading
      </Text>
    </Box>
  );
};

export default SearchJsonDataLoading;
