import { Box, Center, Heading, Icon, Text, VStack } from "@chakra-ui/react";
import { TbAlertTriangle } from "react-icons/tb";

const Error = () => {
  return (
    <Box height="100%" width="100%">
      <Center height="100%" width="100%">
        <VStack spacing={0}>
          <Box>
            <Icon as={TbAlertTriangle} boxSize={20} />
          </Box>
          <Box>
            <Heading as="h3" fontSize="md" textAlign="center">
              Error
            </Heading>
            <Text textAlign="center">Something went wrong</Text>
          </Box>
        </VStack>
      </Center>
    </Box>
  );
};

export default Error;
