import {
  E_SDK_EVENT,
  E_SDK_EVENT_PAYLOAD,
  Mappedin,
  MappedinLocation,
  MappedinMap,
  MapView,
} from "@mappedin/mappedin-js";
import { ReactNode, useEffect, useState } from "react";
import useMapChanged from "../../../hooks/useMapChanged";
import MIMapViewControlLabel from "../MIMapViewControlLabel";
import MIMapViewMapSelectDefault from "./MIMapViewMapSelectDefault";

type MIMapViewMapSelectProps = {
  component?: ReactNode;
  disabled?: boolean;
  mapView?: MapView;
  showLabel?: boolean;
  startLocation?: MappedinLocation;
  venue?: Mappedin;
};

const MIMapViewMapSelect = ({
  component,
  disabled,
  mapView,
  showLabel = false,
  startLocation,
  venue,
}: MIMapViewMapSelectProps) => {
  // State
  const [currentMap, setCurrentMap] = useState<MappedinMap>();
  const [enabled, setEnabled] = useState<boolean>(true);

  // Use map changed
  useMapChanged(
    mapView,
    (_map: E_SDK_EVENT_PAYLOAD[E_SDK_EVENT.MAP_CHANGED]) => {
      // Set current map
      setCurrentMap(_map);
    }
  );

  // Hook
  useEffect(() => {
    // Set current map
    setCurrentMap(mapView?.currentMap);
  }, [mapView?.currentMap]);

  // Hook
  useEffect(() => {
    // If disabled
    if (disabled) {
      // Set enabled
      setEnabled(false);
    } else {
      // Set enabled
      setEnabled(true);
    }

    return () => {
      // Set enabled
      setEnabled(false);
    };
  }, [disabled]);

  return (
    <div
      style={{
        cursor: "pointer",
        opacity: enabled ? 1 : 0.5,
        pointerEvents: enabled ? "all" : "none",
      }}
    >
      {component ? (
        component
      ) : (
        <MIMapViewControlLabel labelText="Select floor" show={showLabel}>
          <MIMapViewMapSelectDefault
            currentMap={currentMap}
            mapView={mapView}
            startLocation={startLocation}
            venue={venue}
          />
        </MIMapViewControlLabel>
      )}
    </div>
  );
};

export default MIMapViewMapSelect;
