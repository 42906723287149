import { List } from "@chakra-ui/react";
import { MappedinLocation } from "@mappedin/mappedin-js";
import SearchMILocationsResultsItem from "./SearchMILocationsResultsItem";

type SearchMILocationsResultsProps = {
  resultsItemClickCallback?: (result: MappedinLocation) => void;
  results?: MappedinLocation[];
};

const SearchMILocationsResults = ({
  results,
  resultsItemClickCallback,
}: SearchMILocationsResultsProps) => {
  return (
    <List className="SearchMILocationsResults">
      {results?.map((result, idx) => (
        <SearchMILocationsResultsItem
          clickCallback={resultsItemClickCallback}
          lastItem={Boolean(idx + 1 === results.length)}
          key={result.id}
          result={result}
        />
      ))}
    </List>
  );
};

export default SearchMILocationsResults;
