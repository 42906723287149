import { useEffect, useState } from "react";
import { MappedinPolygon } from "@mappedin/mappedin-js";

const usePolygonById = (
  polygons: MappedinPolygon[] | undefined,
  id: string | undefined
) => {
  // State
  const [polygon, setPolygon] = useState<MappedinPolygon>();

  // Hook
  useEffect(() => {
    // If polygons is null or undefined
    if (polygons == null) return;

    // If id is null or undefined
    if (id == null) return;

    // Define polygon
    const _polygon = polygons?.find((location) => location.id === id);

    // If location is null or undefined
    if (_polygon == null) return;

    // Set polygon
    setPolygon(_polygon);

    return () => {};
  }, [id, polygons]);

  return polygon;
};

export default usePolygonById;
