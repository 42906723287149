import { Box } from "@chakra-ui/react";
import Navigation from "../Navigation/Navigation";

const Footer = () => {
  return (
    <Box as="footer" backgroundColor="black" flexShrink={0} width="100%">
      <Navigation />
    </Box>
  );
};

export default Footer;
