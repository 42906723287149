import {
  ACTION_TYPE,
  BEARING_TYPE,
  MappedinDirections,
} from "@mappedin/mappedin-js";

const customMarriotDirections = (directions: MappedinDirections) => {
  // Arrival instruction index
  const arrivalInstructionIndex: number = directions.instructions.findIndex(
    (instructionItem: any) => {
      return instructionItem.instruction.includes("Arrive");
    }
  );

  // If arrival instruction null or undefined
  if (arrivalInstructionIndex == null) {
    return directions;
  }

  // Define previous arrival instruction
  const prevArrivalInstruction =
    directions.instructions[arrivalInstructionIndex];

  // Modify previous arrival instruction
  prevArrivalInstruction.instruction = "Cross Mission street";

  // If previous arrival instruction action
  if (prevArrivalInstruction.action) {
    // Define action
    prevArrivalInstruction.action = {
      type: ACTION_TYPE.TURN,
      bearing: BEARING_TYPE.STRAIGHT,
    };
  }

  // Push new arrival instruction
  directions.instructions.push({
    action: {
      type: ACTION_TYPE.TURN,
      bearing: BEARING_TYPE.RIGHT,
    },
    distance: 10,
    instruction:
      "Turn right and enter Marriott Marquis via the front door in the Valet Loop",
    node: prevArrivalInstruction.node,
  });

  // Push new arrival instruction
  directions.instructions.push({
    action: {
      type: ACTION_TYPE.ARRIVAL,
    },
    distance: 0,
    instruction:
      "Follow signs for Yerba Buena Level",
    node: prevArrivalInstruction.node,
  });

  return directions;
};

export default customMarriotDirections;
