import { Icon } from "@chakra-ui/react";
import {
  ACTION_TYPE,
  BEARING_TYPE,
  MappedinVortex,
} from "@mappedin/mappedin-js";
import { IAction } from "@mappedin/mappedin-js/get-venue/MappedinDirections";
import {
  TbArrowBearLeft,
  TbArrowBearRight,
  TbArrowLeft,
  TbArrowRight,
  TbArrowUp,
  TbLocation,
  TbElevator,
  TbMapPin,
  TbStairs,
  TbStepOut,
} from "react-icons/tb";

type DirectionsInstructionsIconProps = {
  action: IAction;
  atLocation: MappedinVortex | undefined;
};

const DirectionsInstructionsIcon = ({
  action,
  atLocation,
}: DirectionsInstructionsIconProps) => {
  // Define instructionIcon
  const InstructionIcon = (
    action: IAction,
    atLocation: MappedinVortex | undefined
  ) => {
    // Switch on action type
    switch (action.type) {
      case ACTION_TYPE.ARRIVAL:
        return <Icon as={TbMapPin} />;
      case ACTION_TYPE.DEPARTURE:
        return <Icon as={TbLocation} />;
      case ACTION_TYPE.EXITVORTEX:
        // Switch on atLocation.type
        switch (atLocation?.type) {
          case "escalator":
            return <Icon as={TbStepOut} />;
          case "elevator":
            return <Icon as={TbStepOut} />;
          case "stairs":
            return <Icon as={TbStepOut} />;
          default:
            break;
        }
        break;
      case ACTION_TYPE.TAKEVORTEX:
        // Switch on atLocation.type
        switch (atLocation?.type) {
          case "escalator":
            return <Icon as={TbStairs} />;
          case "elevator":
            return <Icon as={TbElevator} />;
          case "stairs":
            return <Icon as={TbStairs} />;
          default:
            break;
        }
        break;
      case ACTION_TYPE.TURN:
        // Switch on bearing
        switch (action.bearing) {
          case BEARING_TYPE.LEFT:
            return <Icon as={TbArrowLeft} />;
          case BEARING_TYPE.RIGHT:
            return <Icon as={TbArrowRight} />;
          case BEARING_TYPE.SLIGHTLEFT:
            return <Icon as={TbArrowBearLeft} />;
          case BEARING_TYPE.SLIGHTRIGHT:
            return <Icon as={TbArrowBearRight} />;
          case BEARING_TYPE.STRAIGHT:
            return <Icon as={TbArrowUp} />;
          default:
            break;
        }
        break;
      default:
        break;
    }

    return <></>;
  };
  return InstructionIcon(action, atLocation);
};

export default DirectionsInstructionsIcon;
