import { extendTheme, theme as defaultTheme } from "@chakra-ui/react";

const theme = extendTheme({
  defaultTheme,
  breakpoints: {
    ...defaultTheme.breakpoints,
    "3xl": "112em",
    "4xl": "128em",
    "5xl": "200em",
  },
  colors: {
    ...defaultTheme.colors,
    black: {
      50: "#000000",
      100: "#000000",
      200: "#000000",
      300: "#000000",
      400: "#000000",
      500: "#000000",
      600: "#000000",
      700: "#000000",
      800: "#000000",
      900: "#000000",
    },
    brandPrimary: {
      50: "#F2EAFA",
      100: "#DAC5F2",
      200: "#C3A0E9",
      300: "#AB7BE0",
      400: "#9355D8",
      500: "#7C30CF",
      600: "#6327A5",
      700: "#4A1D7C",
      800: "#311353",
      900: "#190A29",
    },
    gray: {
      50: "#fafafa",
      100: "#f1f1f1",
      200: "#e7e7e7",
      300: "#d4d4d4",
      400: "#adadad",
      500: "#7f7f7f",
      600: "#545454",
      700: "#373737",
      800: "#202020",
      900: "#191919",
    },
    white: {
      50: "#FFFFFF",
      100: "#FFFFFF",
      200: "#FFFFFF",
      300: "#FFFFFF",
      400: "#FFFFFF",
      500: "#FFFFFF",
      600: "#FFFFFF",
      700: "#FFFFFF",
      800: "#FFFFFF",
      900: "#FFFFFF",
    },
  },
  config: {
    initialColorMode: "light",
    useSystemColorMode: false,
  },
  fonts: {
    heading: `'Raleway', sans-serif`,
    body: `'Open Sans', sans-serif`,
  },
  styles: {
    global: {
      body: {
        height: "100%",
        width: "100%",
      },
      html: {
        fontSize: [
          "14px",
          "14px",
          "16px",
          "16px",
          "20px",
          "24px",
          "24px",
          "28px",
        ],
        height: "100%",
        overflow: "hidden",
        width: "100%",
        "@media(min-height: 2160px)": {
          fontSize: "36px",
        },
      },
      "*:not(#startLocationIdInput)": {
        WebkitTouchCallout: "none !important",
        WebkitUserSelect: "none !important",
      },
      "#root": {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        overflow: "hidden",
        width: "100%",
      },
    },
  },
});

export default theme;
