import { useEffect, useState } from "react";

import { useSearchParams } from "react-router-dom";

const useDefaultMapTilt = () => {
  // Search params
  const [searchParams] = useSearchParams();

  // State
  const [defaultMapTilt, setDefaultMapTilt] = useState<number>();

  // Hook
  useEffect(() => {
    // Search params default map rotation
    const searchParamsDefaultMapTilt = searchParams.get("defaultMapTilt");

    // Local storage default map rotation
    const localStorageDefaultMapTilt = localStorage.getItem("defaultMapTilt");

    // Define default map rotation
    const _defaultMapTilt = parseInt(
      searchParamsDefaultMapTilt ?? localStorageDefaultMapTilt ?? "0"
    );

    // If default map rotation is NaN
    if (isNaN(_defaultMapTilt)) {
      // Set default map rotation
      setDefaultMapTilt(0);

      return;
    }

    // Set default map rotation
    setDefaultMapTilt(_defaultMapTilt);

    return () => {};
  }, [searchParams]);

  // Hook
  useEffect(() => {
    // If default map rotation is null or undefined
    if (defaultMapTilt == null) return;

    // Set local storage default map rotation
    localStorage.setItem("defaultMapTilt", String(defaultMapTilt));

    return () => {};
  }, [defaultMapTilt]);

  return defaultMapTilt;
};

export default useDefaultMapTilt;
