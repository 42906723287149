import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Center,
  FormControl,
  FormLabel,
  Input,
  VStack,
} from "@chakra-ui/react";
import { FormEvent, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { venueOptions } from "../../App";
import appConfig from "../../config/appConfig";
import { useGlobalState } from "../../context/GlobalStateProvider";
import useVenue from "../../hooks/useVenue";
import Loading from "../Loading/Loading";
import withLoading, { WithLoadingProps } from "../WithLoading/WithLoading";

type SetupProps = WithLoadingProps & {};

const Setup = ({ setIsLoading }: SetupProps) => {
  // Global context
  const {
    setState,
    state: { redirectAfterFallbackRoute, startLocation },
  } = useGlobalState();

  // Venue
  const venue = useVenue(venueOptions);

  // Location
  const location = useLocation();

  // Navigate
  const navigate = useNavigate();

  // State
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
  const [startLocationIdInputValue] = useState("");

  // Define should set up manually
  const shouldSetUpManually = (() => {
    // If start location defined
    if (startLocation) return false;

    // If allow manual setup
    if (!appConfig.disableManualSetup) return true;

    return false;
  })();
  

  // Hook
  useEffect(() => {
    // Define loading timeout
    const loadingTimeout = setTimeout(() => {
      // If should set up manually
      if (shouldSetUpManually) {
        // Set is loading
        setIsLoading(false);
      } else {
        // Navigate to error
        navigate("/error", { replace: true });

        return;
      }
    }, 5000);

    return () => {
      // Set is loading
      setIsLoading(true);

      // Clear loading timeout
      clearTimeout(loadingTimeout);
    };
  }, [navigate, setIsLoading, shouldSetUpManually]);

  // Hook
  useEffect(() => {
    // If start location is null or undefined
    if (startLocation == null) return;

    // If redirect after fallback route is null or undefined
    if (redirectAfterFallbackRoute == null) {
      // Navigate to app root
      navigate("/", { replace: true });

      return;
    }

    // Navigate to redirect after fallback route
    navigate(redirectAfterFallbackRoute + location.search);

    return () => {};
  }, [
    location.search,
    navigate,
    redirectAfterFallbackRoute,
    setIsLoading,
    startLocation,
  ]);

  // Handle form submit
  const handleFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    // Prevent default
    event.preventDefault();

    // Define form
    const form = event.currentTarget;

    // Define form elements
    const formElements = form.elements as typeof form.elements & {
      startLocationIdInput: HTMLInputElement;
    };

    // Find start location in venue locations
    const _startLocation = venue?.locations.find(
      (location) => location.id === formElements.startLocationIdInput.value
    );

    // If start location defined
    if (_startLocation) {
      // Set start location
      setState((prev) => ({ ...prev, startLocation: _startLocation }));

      // Set local storage start location id
      localStorage.setItem(
        "startLocationId",
        formElements.startLocationIdInput.value
      );

      // Navigate to redirect after setup
      navigate(redirectAfterFallbackRoute ?? "/", { replace: true });
    } else {

      console.log("here")
      // Set show error message
      setShowErrorMessage(true);
    }
  };

  return (
    <Center height="100%" width="100%">
      <Box width="50vw">
        <VStack spacing={4} width="100%">
          {showErrorMessage && (
            <Alert status="error">
              <AlertIcon />
              The start location ID you entered is invalid
            </Alert>
          )}
          <Box width="100%">
            <form onSubmit={handleFormSubmit}>
              <FormControl>
                <VStack spacing={4} width="100%">
                  <Box width="100%">
                    <FormLabel textAlign="center">
                      Enter start location ID
                    </FormLabel>
                    <Input
                      id="startLocationIdInput"
                      isInvalid={showErrorMessage}
                      defaultValue={startLocationIdInputValue}
                    />
                  </Box>
                  <Box width="100%">
                    <Button type="submit" width="100%">
                      Submit
                    </Button>
                  </Box>
                </VStack>
              </FormControl>
            </form>
          </Box>
        </VStack>
      </Box>
    </Center>
  );
};

export default withLoading(Setup, <Loading message="Loading app" />);
