import {
  Box,
  Button,
  Collapse,
  Flex,
  Hide,
  LayoutProps,
  useDisclosure,
} from "@chakra-ui/react";
import { ReactNode } from "react";
import { TbList } from "react-icons/tb";

type CollapsingSidebarLayoutProps = {
  mainContent: ReactNode;
  mainContentMinHeight: LayoutProps["minHeight"];
  sidebarContent: ReactNode;
};

const CollapsingSidebarLayout = ({
  mainContent,
  mainContentMinHeight,
  sidebarContent,
}: CollapsingSidebarLayoutProps) => {
  // Disclosure
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Flex
      className="CollapsingSidebarLayout"
      direction={["column", "column", "row-reverse"]}
      height="100%"
      overflow="hidden"
    >
      <Flex
        className="MainContent"
        flexDirection="column"
        flexGrow={1}
        minHeight={mainContentMinHeight}
        overflow="hidden"
      >
        {mainContent}
      </Flex>
      <Hide above="sm">
        <Button
          borderRadius={0}
          className="CollapseButton"
          colorScheme="brandPrimary"
          height="auto"
          leftIcon={<TbList />}
          onClick={onToggle}
          paddingY={[2, 2, 4]}
        >
          {`${isOpen ? "Hide" : "Show"} steps`}
        </Button>
      </Hide>
      <Box
        animateOpacity={false}
        as={Collapse}
        className="Sidebar"
        display={[null, null, "flex !important"]}
        height={[null, null, "auto !important"]}
        in={isOpen}
        opacity={["1 !important"]}
        width={[null, null, "40%", "35%", "35%", "35%", "30%", "40%", "25%"]}
      >
        <Flex
          className="SidebarInner"
          flexDirection="column"
          height="100%"
          width="100%"
        >
          {sidebarContent}
        </Flex>
      </Box>
    </Flex>
  );
};

export default CollapsingSidebarLayout;
