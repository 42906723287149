import {
  Badge,
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Heading,
  ListItem,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { format } from "date-fns";
import { FaDirections } from "react-icons/fa";
import appConfig from "../../config/appConfig";

type SearchJsonDataResultItemProps = {
  result: any;
};

const SearchJsonDataResultsItem = ({
  result,
}: SearchJsonDataResultItemProps) => {
  // Location
  const location = useLocation();

  return (
    <ListItem className="SearchJsonDataResultsItem" paddingX={[4, 4, 6]}>
      <Card>
        <CardHeader>
          {result.session_abbreviation && (
            <Badge colorScheme="brandPrimary" marginBottom={[2, 2, 4]}>
              {result.session_abbreviation}
            </Badge>
          )}
          <Heading size="md">{result.sessiontitle}</Heading>
          <VStack
            className="VStack"
            marginTop={[2, 2, 4]}
            spacing={[1, 1, 2]}
            width="100%"
          >
            <Box className="Box" width="100%">
              {
                <Text>
                  {format(new Date(result.starttime), "eeee, MMMM do")}
                  {` from `}
                  {format(new Date(result.starttime), "h:mm a")}
                  &ndash;
                  {format(new Date(result.endtime), "h:mm a")}
                </Text>
              }
            </Box>
            <Box className="Box" width="100%">
              {result.roomnametoshow && <Text>{result.roomnametoshow}</Text>}
            </Box>
          </VStack>
        </CardHeader>
        {!appConfig.disableDirections &&
          result.campuswayfindingpointid.length > 0 && (
            <CardBody>
              <Button
                as={RouterLink}
                className="SearchJsonDataResultsItemGetDirectionsButton"
                colorScheme="brandPrimary"
                leftIcon={<FaDirections />}
                state={{ from: location }}
                to={`/directions/${result.campuswayfindingpointid}`}
              >
                Get directions
              </Button>
            </CardBody>
          )}
      </Card>
    </ListItem>
  );
};

export default SearchJsonDataResultsItem;
