import { E_CAMERA_EVENT, MapView } from "@mappedin/mappedin-js";
import { MouseEvent, ReactNode, useCallback, useEffect, useState } from "react";
import MIMapViewCompassDefault from "./MIMapViewCompassDefault";

type MIMapViewCompassProps = {
  component?: ReactNode;
  defaultMapRotation?: number;
  mapView?: MapView;
};

const MIMapViewCompass = ({
  component,
  defaultMapRotation,
  mapView,
}: MIMapViewCompassProps) => {
  // State
  const [enabled, setEnabled] = useState<boolean>(true);

  // State
  const [currentCameraRotation, setCurrentCameraRotation] = useState<number>(
    defaultMapRotation ?? 0
  );

  // Handle changed
  const handleChanged = useCallback(() => {
    // Set current camera rotation
    setCurrentCameraRotation(((mapView?.Camera.rotation ?? 0) * 180) / Math.PI);

    // If current camera rotation is not 0
    if (mapView?.Camera.rotation !== 0) {
      // Set enabled
      setEnabled(true);
    } else {
      // Set enabled
      setEnabled(false);
    }
  }, [mapView?.Camera.rotation]);

  // Handle click
  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    // If current camera rotation is not zero
    if (currentCameraRotation !== 0) {
      // Animate map view camera
      mapView?.Camera.animate({ rotation: 0 }, { duration: 200 });

      // Set current camera rotation
      setCurrentCameraRotation(0);
    }
  };

  // Hook
  useEffect(() => {
    // On map view camera changed
    mapView?.Camera.on(E_CAMERA_EVENT.CHANGED, handleChanged);

    return () => {
      // Off map view camera changed
      mapView?.Camera.off(E_CAMERA_EVENT.CHANGED, handleChanged);
    };
  }, [handleChanged, mapView?.Camera]);

  return (
    <div
      onClick={handleClick}
      style={{
        cursor: "pointer",
        opacity: enabled ? 1 : 0.5,
        pointerEvents: enabled ? "all" : "none",
        transform: `rotate(${currentCameraRotation}deg)`,
        transition: "transform 0.2s ease-in-out",
      }}
    >
      {component ? component : <MIMapViewCompassDefault />}
    </div>
  );
};

export default MIMapViewCompass;
