import { ReactNode } from "react";
import { Box } from "@chakra-ui/react";

type MainProps = {
  children?: ReactNode;
};

const Main = ({ children }: MainProps) => {
  return (
    <Box as="main" className="Main" flexGrow={1} overflow="hidden">
      {children}
    </Box>
  );
};

export default Main;
