import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  Flex,
  Heading,
  HStack,
  List,
  ListItem,
  Text,
  VStack,
} from "@chakra-ui/react";
import { FaDirections } from "react-icons/fa";
import { Link as RouterLink } from "react-router-dom";
import { useGlobalState } from "../../context/GlobalStateProvider";
import withIdleTimeout from "../WithIdleTimeout/WithIdleTimeout";

const PubCrawl = () => {
  // Global context
  const {
    state: { venue },
  } = useGlobalState();

  return (
    <Flex className="PubCrawl" direction="row" height="100%" overflowY="hidden">
      <Container className="Container" maxW="container.2xl" paddingX={0}>
        <HStack height="100%">
          <Box
            className="StackBox"
            height="100%"
            paddingX={[4, 4, 6]}
            paddingY={[4, 4, 6]}
            width="100%"
          >
            <Heading marginBottom={[4, 4, 6]}>Pub Crawl</Heading>
            <Text fontSize="xl" marginBottom={4}>
              Visit participating sponsors and learn about their latest products
              and offerings while you sample beer, wine, and non-alcoholic
              beverages located among sponsors' booths.
            </Text>
            <Text fontSize="xl">Wednesday 4:30 &ndash; 6 PM</Text>
          </Box>
          <VStack
            className="VStack"
            height="100%"
            overflowY="scroll"
            spacing={4}
            width="100%"
          >
            <Box className="StackBox" width="100%">
              <List paddingX={[4, 4, 6]} paddingY={[4, 4, 6]} spacing={4}>
                {venue?.locations
                  .filter(({ tags }) => tags?.includes("Pub Crawl"))
                  .sort((a, b) => {
                    if (a.name < b.name) {
                      return -1;
                    }
                    if (a.name > b.name) {
                      return 1;
                    }
                    return 0;
                  })
                  .map((location) => (
                    <ListItem key={location.id}>
                      <Card>
                        <CardHeader>
                          <Heading as="h2" fontSize="2xl">
                            {location.name}
                          </Heading>
                        </CardHeader>
                        <CardBody>
                          <Button
                            as={RouterLink}
                            className="LocationGetDirectionsButton"
                            colorScheme="brandPrimary"
                            leftIcon={<FaDirections />}
                            state={{ from: location }}
                            to={`/directions/${location.id}/${location.polygons[0].id}`}
                          >
                            Get directions
                          </Button>
                        </CardBody>
                      </Card>
                    </ListItem>
                  ))}
              </List>
            </Box>
          </VStack>
        </HStack>
      </Container>
    </Flex>
  );
};

export default withIdleTimeout(PubCrawl);
