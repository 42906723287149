import { Dispatch, ReactNode, SetStateAction, useState } from "react";
import WithLoadingLoading from "./WithLoadingLoading";

export type WithLoadingProps = {
  setIsLoading: Dispatch<SetStateAction<boolean>>;
};

const withLoading = <T extends WithLoadingProps = WithLoadingProps>(
  WrappedComponent: React.ComponentType<T>,
  CustomLoadingElement?: ReactNode
) => {
  // Try to create a nice displayName for React Dev Tools.
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || "Component";

  // Creating the inner component. The calculated Props type here is the where the magic happens.
  const ComponentWithLoading = (props: Omit<T, keyof WithLoadingProps>) => {
    // State
    const [isLoading, setIsLoading] = useState<boolean>(true);

    return (
      <>
        {isLoading &&
          (CustomLoadingElement ? (
            CustomLoadingElement
          ) : (
            <WithLoadingLoading />
          ))}
        <WrappedComponent {...(props as T)} setIsLoading={setIsLoading} />
      </>
    );
  };

  ComponentWithLoading.displayName = `withLoading(${displayName})`;

  return ComponentWithLoading;
};

export default withLoading;
