import NavigationButton from "./NavigationButton";
import { Icon, Text, VStack } from "@chakra-ui/react";
import { TbSquareRoundedPlusFilled } from "react-icons/tb";
import { NavigationState } from "./Navigation";

type NavigationNewExhibitorsButtonProps = {
  navigationState?: NavigationState;
};

const NavigationNewExhibitorsButton = ({
  navigationState,
}: NavigationNewExhibitorsButtonProps) => {
  return (
    <NavigationButton
      className="NavigationNewExhibitorsButton"
      isDisabled={
        navigationState === NavigationState.NEW_EXHIBITORS ? true : false
      }
      to="/early-stage-expo"
    >
      <VStack justifyContent="center" spacing={1} width="100%">
        <Icon as={TbSquareRoundedPlusFilled} boxSize={6} />
        <Text fontSize="0.75rem" fontWeight={100} textTransform="uppercase">
          Early Stage Expo
        </Text>
      </VStack>
    </NavigationButton>
  );
};

export default NavigationNewExhibitorsButton;
