import { MouseEvent, useEffect, useRef, useState } from "react";
import { Button, Input, InputGroup, InputRightElement } from "@chakra-ui/react";
import { ChangeEvent, FocusEvent } from "react";
import { TbX } from "react-icons/tb";
import { MappedinLocation } from "@mappedin/mappedin-js";
import Keyboard from "../Keyboard/Keyboard";
import appConfig from "../../config/appConfig";

type SearchMILocationsInputProps = {
  blurCallback?: (value: string) => void;
  changeCallback?: (value: string) => void;
  clearButtonClickCallback?: () => void;
  focusCallback?: (value: string) => void;
  selectedLocation?: MappedinLocation;
};

const SearchMILocationsInput = ({
  blurCallback,
  changeCallback,
  clearButtonClickCallback,
  focusCallback,
  selectedLocation,
}: SearchMILocationsInputProps) => {
  // Ref
  const keyboardRef = useRef<any>(null);

  // State
  const [showKeyboard, setShowKeyboard] = useState<boolean>(false);
  const [value, setValue] = useState<string>("");

  // Handle blur
  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    // Call blur callback
    blurCallback && blurCallback(event.target.value);
  };

  // Handle input change
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    // Set value
    setValue(event.target.value);
  };

  // Handle clear button click
  const handleClearButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    // If value is defined
    if (value) {
      // Set value
      setValue("");
    }

    // Call clear button click callback
    clearButtonClickCallback && clearButtonClickCallback();
  };

  // Handle blur
  const handleFocus = (event: FocusEvent<HTMLInputElement>) => {
    // Set show keyboard
    setShowKeyboard(true);

    // Call focus callback
    focusCallback && focusCallback(event.target.value);
  };

  // Hook
  useEffect(() => {
    // If selected location null or undefined
    if (selectedLocation == null) return;

    // Set value
    setValue(selectedLocation.name);

    return () => {
      // Set value
      setValue("");
    };
  }, [selectedLocation]);

  // Hook
  useEffect(() => {
    // Define keyboard ref current
    const keyboardRefCurrent = keyboardRef.current;

    // Set input on keyboard ref current
    keyboardRefCurrent.setInput(value);

    // Call change callback
    changeCallback && changeCallback(value);

    return () => {
      // Set input on keyboard ref current
      keyboardRefCurrent.setInput("");
    };
  }, [changeCallback, value]);

  return (
    <>
      <InputGroup>
        <Input
          autoComplete="off"
          borderWidth="0.125rem"
          className="SearchMILocationsInput"
          id="keyboard-target"
          inputMode="none"
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={handleFocus}
          placeholder={`Search ${
            appConfig.locationsExpoOnly ? "booths" : "locations"
          }`}
          value={value}
        />
        {value && (
          <InputRightElement paddingX={2} width="auto">
            <Button
              flexShrink={0}
              onClick={handleClearButtonClick}
              rightIcon={<TbX />}
              size="xs"
            >
              Clear
            </Button>
          </InputRightElement>
        )}
      </InputGroup>
      <Keyboard
        keyboardRef={keyboardRef}
        setShowKeyboard={setShowKeyboard}
        setTargetInputValue={setValue}
        showKeyboard={showKeyboard}
      />
    </>
  );
};

export default SearchMILocationsInput;
