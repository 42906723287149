const appConfig = {
  disableDirections:
    process.env.REACT_APP_DISABLE_DIRECTIONS === "true" ? true : false,
  disableDirectionsQrCode:
    process.env.REACT_APP_DISABLE_DIRECTIONS_QR_CODE === "true" ? true : false,
  disableLocations:
    process.env.REACT_APP_DISABLE_LOCATIONS === "true" ? true : false,
  disableNewExhibitors:
    process.env.REACT_APP_DISABLE_NEW_EXHIBITORS === "true" ? true : false,
  disablePubCrawl:
    process.env.REACT_APP_DISABLE_PUB_CRAWL === "true" ? true : false,
  disableManualSetup:
    process.env.REACT_APP_DISABLE_MANUAL_SETUP === "true" ? true : false,
  disableSchedule:
    process.env.REACT_APP_DISABLE_SCHEDULE === "true" ? true : false,
  disableStart: process.env.REACT_APP_DISABLE_START === "true" ? true : false,
  disableTimeout:
    process.env.REACT_APP_DISABLE_TIMEOUT === "true" ? true : false,
  locationsExpoOnly:
    process.env.REACT_APP_LOCATIONS_EXPO_ONLY === "true" ? true : false,
  qrCodeUrl: process.env.REACT_APP_QR_CODE_URL
    ? process.env.REACT_APP_QR_CODE_URL
    : window.location.hostname,
};

export default appConfig;
