import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  Input,
  useBreakpointValue,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import MIMapView, { mapViewCameraFocusOnOptions } from "../MIMapView/MIMapView";
import { useGlobalState } from "../../context/GlobalStateProvider";
import { MappedinLocation, MapView } from "@mappedin/mappedin-js";
import withLoading, { WithLoadingProps } from "../WithLoading/WithLoading";
import Loading from "../Loading/Loading";
import useSelectedLocation from "../../hooks/useSelectedLocation";
import { TbLocation, TbMapPin } from "react-icons/tb";
import { FaDirections } from "react-icons/fa";
import { Link as RouterLink, useLocation } from "react-router-dom";
import MapWrapper from "../MapWrapper/MapWrapper";
import theme from "../../theme";
import SearchMILocationsInput from "./SearchMILocationsInput";
import SearchMILocationsLoading from "./SearchMILocationsLoading";
import SearchMILocationsNoResults from "./SearchMILocationsNoResults";
import useOfflineSearch from "../../hooks/useOfflineSearch";
import SearchMILocationsResults from "./SearchMILocationsResults";
import appConfig from "../../config/appConfig";
import withIdleTimeout from "../WithIdleTimeout/WithIdleTimeout";

const startLocationMarkerMarkup = `
<div style="width: 32px; height: 32px">
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 468.614 468.614">
    <g>
        <path d="M291.299 116.517h-3.501c10.635-12.36 17.102-28.378 17.102-45.915C304.899 31.666 273.219 0 234.305 0c-38.93 0-70.584 31.666-70.584 70.602 0 17.537 6.477 33.555 17.093 45.915h-3.51c-19.645 0-35.626 15.997-35.626 35.645v117.105c0 19.648 15.982 35.633 35.626 35.633h1.372v128.07c0 20.332 10.697 35.645 24.884 35.645h61.468c14.208 0 24.908-15.312 24.908-35.645V304.9h1.363c19.642 0 35.639-15.984 35.639-35.633V152.161c-.006-19.647-15.998-35.644-35.639-35.644z" fill="#386460"></path>
        <path d="M291.299 116.517h-3.501c10.635-12.36 17.102-28.378 17.102-45.915C304.899 31.666 273.219 0 234.305 0c-38.93 0-70.584 31.666-70.584 70.602 0 17.537 6.477 33.555 17.093 45.915h-3.51c-19.645 0-35.626 15.997-35.626 35.645v117.105c0 19.648 15.982 35.633 35.626 35.633h1.372v128.07c0 20.332 10.697 35.645 24.884 35.645h61.468c14.208 0 24.908-15.312 24.908-35.645V304.9h1.363c19.642 0 35.639-15.984 35.639-35.633V152.161c-.006-19.647-15.998-35.644-35.639-35.644z" style="fill:none;stroke-width:8px;stroke:white"></path>
    </g>
  </svg>
  <div></div>
</div>
`;

enum SearchState {
  LOADING = "LOADING",
  NO_RESULTS = "NO_RESULTS",
  NONE = "NONE",
  RESULTS = "RESULTS_FOUND",
}

type SearchMILocationsProps = WithLoadingProps & {};

const SearchMILocations = ({ setIsLoading }: SearchMILocationsProps) => {
  // Global context
  const {
    setState,
    state: {
      defaultMapRotation,
      defaultMapTilt,
      mapView,
      startLocation,
      venue,
    },
  } = useGlobalState();

  // Location
  const location = useLocation();

  // State
  const [inputFocused, setInputFocused] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [searchState, setSearchState] = useState<SearchState>(SearchState.NONE);
  const { selectedLocation, setSelectedLocation } = useSelectedLocation(
    mapView,
    venue,
    theme.colors.brandPrimary[500],
    appConfig.locationsExpoOnly ? true : false
  );

  // Search response
  const searchResponse = useOfflineSearch(venue, searchQuery);

  // Memoize search response results
  const searchResults = useMemo(
    () =>
      searchResponse?.results
        .filter((result) => result.type === "MappedinLocation")
        .map(({ object }) => {
          return object as MappedinLocation;
        })
        .filter((result) => !result.tags?.includes("omitFromLocationSearch")),
    [searchResponse]
  );

  // Clear button click callback
  const clearButtonClickCallback = useCallback(() => {
    // Set selected location
    setSelectedLocation(undefined);
  }, [setSelectedLocation]);

  // Input blur callback
  const inputBlurCallback = useCallback((inputValue: string) => {
    // Set input focused
    setInputFocused(false);
  }, []);

  // Input change callback
  const inputChangeCallback = useCallback((inputValue: string) => {
    // Set search query
    setSearchQuery(inputValue);
  }, []);

  // Input focus callback
  const inputFocusCallback = useCallback((inputValue: string) => {
    // Set input focused
    setInputFocused(true);
  }, []);

  // Results item click callback
  const resultsItemClickCallback = useCallback(
    (result: MappedinLocation) => {
      // Create writeable type
      type Writeable<T> = { -readonly [P in keyof T]: T[P] };

      // Cast location as writeable
      const writeableLocation = result as Writeable<MappedinLocation>;

      // Sort writeable location polygons
      writeableLocation.polygons = writeableLocation.polygons.sort((a, b) => {
        // Define first distance
        const firstDist: number = !!startLocation?.distanceTo(a, {
          accessible: true,
        })
          ? startLocation?.distanceTo(a, { accessible: true })
          : Number.MAX_SAFE_INTEGER; // Returns max safe int if distance to falsy

        // Define second distance
        const secondDist: number = !!startLocation?.distanceTo(b, {
          accessible: true,
        })
          ? startLocation?.distanceTo(b, { accessible: true })
          : Number.MAX_SAFE_INTEGER; // Returns max safe int if distance to falsy

        // Return difference of distances
        return firstDist - secondDist;
      });

      // Set selected location
      setSelectedLocation(writeableLocation as MappedinLocation);
    },
    [setSelectedLocation, startLocation]
  );

  // Set map view
  const setMapView = useCallback(
    (mv: MapView | undefined) => {
      // If map view is null or undefined
      if (mv == null) return;

      // Set state
      setState((prev) => ({ ...prev, mapView: mv }));
    },
    [setState]
  );

  // Show controls labels
  const showControlsLabels = useBreakpointValue({
    base: false,
    md: true,
  });

  // Hook on start location
  useEffect(() => {
    // If map view is null or undefined
    if (mapView == null) return;

    // If start location is null or undefined
    if (startLocation == null) return;

    // Create you are here marker
    const youAreHereMarker = mapView?.createMarker(
      startLocation.nodes[0],
      startLocationMarkerMarkup,
      {
        rank: 4,
      }
    );

    // Add style overrides to you are here marker element
    (youAreHereMarker._el as HTMLDivElement).style.pointerEvents = "all";
    (youAreHereMarker._el as HTMLDivElement).style.cursor = "pointer";
    (youAreHereMarker._el as HTMLDivElement).onclick = (event) => {
      // Focus on start location polygons
      mapView.Camera.focusOn(
        { polygons: startLocation.polygons },
        mapViewCameraFocusOnOptions
      );

      // Trigger user interaction end functions
      mapView.Camera._subscribers.USER_INTERACTION_END.forEach(
        (fn: Function) => {
          // Call function
          fn();
        }
      );
    };

    // For each polygon in start location
    startLocation.polygons.forEach((polygon) => {
      // Remove interactivity
      mapView?.removeInteractivePolygon(polygon);
    });

    // Set map view map
    mapView.setMap(startLocation.polygons[0].map.id).then(() => {
      // Set timeout
      setTimeout(() => {
        // Set is loading
        setIsLoading(false);
      }, 200);
    });
  }, [mapView, setIsLoading, startLocation]);

  // Hook on selected location
  useEffect(() => {
    // If selected location null or undefined
    if (selectedLocation == null) return;

    // Set map to be selected location's map
    mapView?.setMap(selectedLocation.polygons[0].map.id).then(() => {
      // Focus on polygon for location
      mapView?.Camera.focusOn(
        {
          polygons: [selectedLocation.polygons[0]],
        },
        {
          minZoom: 1500,
          rotation: ((defaultMapRotation ?? 0) * Math.PI) / 180,
          tilt: 0,
        }
      ).then(() => {
        // Trigger user interaction end functions
        mapView.Camera._subscribers.USER_INTERACTION_END.forEach(
          (fn: Function) => {
            // Call function
            fn();
          }
        );
      });
    });
  }, [defaultMapRotation, mapView, mapView?.Camera, selectedLocation]);

  // Hook on search response query and search query
  useEffect(() => {
    // If search query does not match response query
    if (searchQuery && searchQuery !== searchResponse.query) {
      // Set search state
      setSearchState(SearchState.LOADING);
    }

    return () => {
      // Set search state
      setSearchState(SearchState.NONE);
    };
  }, [searchResponse.query, searchQuery]);

  // Hook on search response query length and search response results length
  useEffect(() => {
    // If response results length is zero and response query length is not zero
    if (
      searchResponse.results.length === 0 &&
      searchResponse.query.length !== 0
    ) {
      // Set search state
      setSearchState(SearchState.NO_RESULTS);
    }

    return () => {
      // Set search state
      setSearchState(SearchState.NONE);
    };
  }, [searchResponse.query.length, searchResponse.results.length]);

  // Hook on search response
  useEffect(() => {
    // If response results length greater than zero
    if (searchResponse.results.length > 0) {
      // Set search state
      setSearchState(SearchState.RESULTS);
    }

    return () => {
      // Set search state
      setSearchState(SearchState.NONE);
    };
  }, [searchResponse.results]);

  return (
    <Flex className="SearchMILocations" flexDirection="column" height="100%">
      <Box flexGrow={1} position="relative" width="100%">
        <Box
          backgroundColor={useColorModeValue("white", "gray.900")}
          borderBottomRightRadius="md"
          boxShadow="0 0.125rem 0.25rem rgba(0,0,0,0.25)"
          className="SearchLocationsWidget"
          paddingX={6}
          paddingY={4}
          position="absolute"
          top={0}
          left={0}
          width={[
            "100%",
            "100%",
            "50vw",
            "50vw",
            "40vw",
            "40vw",
            "35vw",
            "45vw",
            "30vw",
          ]}
          zIndex={999}
        >
          <VStack className="VStack" width="100%">
            <HStack
              alignContent="start"
              className="HStack"
              spacing={2}
              width="100%"
            >
              <Box className="Box">
                <Icon
                  as={TbLocation}
                  boxSize={5}
                  color={useColorModeValue("blue.300", "blue.500")}
                  marginTop={1.5}
                />
              </Box>
              <Box className="Box" flexGrow={1}>
                <Input
                  _focus={{ borderColor: "transparent" }}
                  backgroundColor="transparent !important"
                  variant="filled"
                  defaultValue={startLocation?.name}
                  readOnly
                />
              </Box>
            </HStack>
            <HStack
              alignItems="start"
              className="HStack"
              spacing={2}
              width="100%"
            >
              <Box className="Box">
                <Icon
                  as={TbMapPin}
                  boxSize={5}
                  color={useColorModeValue("red.300", "red.500")}
                  marginTop={2}
                />
              </Box>
              <Box className="Box" width="100%">
                <Box
                  className="SearchMILocationsInput"
                  position="relative"
                  width="100%"
                >
                  <SearchMILocationsInput
                    blurCallback={inputBlurCallback}
                    changeCallback={inputChangeCallback}
                    clearButtonClickCallback={clearButtonClickCallback}
                    focusCallback={inputFocusCallback}
                    selectedLocation={selectedLocation}
                  />
                  <Box
                    backgroundColor={useColorModeValue("white", "gray.700")}
                    className="Box"
                    marginTop={1}
                    position="absolute"
                    top="100%"
                    width="100%"
                    zIndex={999}
                    display={inputFocused ? "block" : "none"}
                  >
                    {searchState === SearchState.LOADING && (
                      <SearchMILocationsLoading />
                    )}
                    {searchState === SearchState.NO_RESULTS && (
                      <SearchMILocationsNoResults />
                    )}
                    {searchState === SearchState.RESULTS && (
                      <SearchMILocationsResults
                        resultsItemClickCallback={resultsItemClickCallback}
                        results={searchResults.slice(0, 5)}
                      />
                    )}
                  </Box>
                </Box>
                {selectedLocation &&
                  !appConfig.disableDirections &&
                  startLocation?.directionsTo(selectedLocation).path.length >
                    0 && (
                    <Button
                      as={RouterLink}
                      className="GetDirectionsButton"
                      colorScheme="brandPrimary"
                      leftIcon={<FaDirections />}
                      marginTop={2}
                      state={{ from: location }}
                      to={`/directions/${selectedLocation.id}/${selectedLocation.polygons[0].id}`}
                      width="100%"
                    >
                      Get directions
                    </Button>
                  )}
              </Box>
            </HStack>
          </VStack>
        </Box>
        <MapWrapper mapView={mapView}>
          <MIMapView
            controls={{
              mapSelectShowLabel: appConfig.locationsExpoOnly
                ? false
                : showControlsLabels,
              zoomIn: useBreakpointValue({ base: false, md: true }),
              zoomInShowLabel: appConfig.locationsExpoOnly
                ? false
                : showControlsLabels,
              zoomOut: useBreakpointValue({ base: false, md: true }),
              zoomOutShowLabel: appConfig.locationsExpoOnly
                ? false
                : showControlsLabels,
            }}
            defaultMapRotation={defaultMapRotation}
            defaultMapTilt={defaultMapTilt}
            setMapView={setMapView}
            startLocation={startLocation}
            venue={venue}
          />
        </MapWrapper>
      </Box>
    </Flex>
  );
};

export default withIdleTimeout(
  withLoading(SearchMILocations, <Loading message="Loading search" />)
);
