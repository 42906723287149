const MIMapViewCompassDefault = () => {
  return (
    <div
      className="MIMapViewCompassDefault"
      style={{
        backgroundColor: "white",
        borderRadius: "99rem",
        boxShadow: "0 0.125rem 0.25rem rgba(0,0,0,0.25)",
        height: "3rem",
        position: "relative",
        width: "3rem",
      }}
    >
      <div
        style={{
          left: "50%",
          position: "absolute",
          top: "50%",
          transform: "translate(-50%, -50%)",
          userSelect: "none",
        }}
      >
        <div
          style={{
            lineHeight: 1,
            position: "relative",
            textAlign: "center",
            top: "-0.0625rem",
          }}
        >
          <span
            style={{
              color: "red",
              display: "inline-block",
              fontFamily: "Arial, Helvetica, sans-serif",
              left: "-0.0rem",
              position: "relative",
              transform: "rotate(-90deg)",
            }}
          >
            &#10148;
          </span>
          <br />
          <span
            style={{
              color: "black",
              display: "inline-block",
              fontFamily: "Arial, Helvetica, sans-serif",
              fontWeight: "bold",
            }}
          >
            N
          </span>
        </div>
      </div>
    </div>
  );
};

export default MIMapViewCompassDefault;
