import { OrderedList } from "@chakra-ui/react";
import { MappedinNode, TMappedinDirective } from "@mappedin/mappedin-js";
import { useGlobalState } from "../../context/GlobalStateProvider";
import DirectionsInstructionsItem from "./DirectionsInstructionsItem";

type InstructionsProps = {
  instructions: TMappedinDirective[] | undefined;
};

const DirectionsInstructions = ({ instructions }: InstructionsProps) => {
  // Global context
  const {
    state: { mapView },
  } = useGlobalState();

  // Define listItemClickHandler
  const instructionClickCallback = (
    stepNumber: number,
    instruction: TMappedinDirective
  ) => {
    // If stepNumber is defined
    if (typeof stepNumber !== "undefined" && instructions) {
      // Define previousStepNode
      const previousStepNode = (() => {
        if (stepNumber > 0) {
          return instructions[stepNumber - 1].node;
        }
        return undefined;
      })();

      // Define stepNode
      const stepNode = instruction.node;

      // If mapView.currentMap is not map of focus node
      if (mapView?.currentMap.id !== stepNode.map.id) {
        // Set map to correct map
        mapView?.setMap(stepNode.map.id);
      }

      // Define focusNodes
      const focusNodes = (() => {
        // If previous step node is same as step node
        if (previousStepNode?.id === stepNode.id) return [stepNode];

        return [previousStepNode, stepNode];
      })();

      // Focus mapView Camera
      mapView?.Camera.focusOn(
        {
          nodes: focusNodes
            .filter((node) => typeof node !== "undefined")
            .filter(
              (node) => node?.map.id === mapView?.currentMap.id
            ) as MappedinNode[],
        },
        { minZoom: 1500 }
      ).then(() => {
        // Trigger user interaction end functions
        mapView.Camera._subscribers.USER_INTERACTION_END.forEach(
          (fn: Function) => {
            // Call function
            fn();
          }
        );
      });
    }
  };

  return (
    <OrderedList
      height="100%"
      listStyleType="none"
      margin="0"
      overflowY="scroll"
    >
      {instructions?.map((instruction, idx) => (
        <DirectionsInstructionsItem
          clickCallback={instructionClickCallback}
          idx={idx}
          instruction={instruction}
          key={idx}
        />
      ))}
    </OrderedList>
  );
};

export default DirectionsInstructions;
