import { useEffect, useState } from "react";

import { MappedinLocation } from "@mappedin/mappedin-js";
import { useSearchParams } from "react-router-dom";

const useStartLocation = (locations: MappedinLocation[] | undefined) => {
  // Search params
  const [searchParams] = useSearchParams();

  // State
  const [startLocation, setStartLocation] = useState<MappedinLocation>();

  useEffect(() => {
    // If locations is null or undefined
    if (locations == null) return;

    // Search params start location id
    const searchParamsStartLocationId = searchParams.get("startLocationId");

    // Local storage start location id
    const localStorageStartLocationId = localStorage.getItem("startLocationId");

    // Define start location id
    const startLocationId =
      searchParamsStartLocationId ?? localStorageStartLocationId ?? undefined;

    // If start location id is null or undefined
    if (startLocationId == null) return;

    // Define start location
    const _startLocation = locations.find(({ id }) => id === startLocationId);

    // If start location is null or undefined
    if (_startLocation == null) return;

    // Set start location
    setStartLocation(_startLocation);

    return () => {};
  }, [searchParams, locations]);

  // Hook
  useEffect(() => {
    // If start location is null or undefined
    if (startLocation == null) return;

    // Set local storage start location id
    localStorage.setItem("startLocationId", startLocation.id);

    return () => {};
  }, [startLocation]);

  return startLocation;
};

export default useStartLocation;
