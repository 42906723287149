import NavigationButton from "./NavigationButton";
import { Icon, Text, VStack } from "@chakra-ui/react";
import { TbCalendarEvent } from "react-icons/tb";
import { NavigationState } from "./Navigation";

type NavigationScheduleButtonProps = {
  navigationState?: NavigationState;
};

const NavigationScheduleButton = ({
  navigationState,
}: NavigationScheduleButtonProps) => {
  return (
    <NavigationButton
      className="NavigationScheduleButton"
      isDisabled={navigationState === NavigationState.SCHEDULE ? true : false}
      to="/schedule"
    >
      <VStack justifyContent="center" spacing={1} width="100%">
        <Icon as={TbCalendarEvent} boxSize={6} />
        <Text fontSize="0.75rem" fontWeight={100} textTransform="uppercase">
          Schedule
        </Text>
      </VStack>
    </NavigationButton>
  );
};

export default NavigationScheduleButton;
