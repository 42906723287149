import { Box } from "@chakra-ui/layout";
import { Dispatch, useState, MutableRefObject, SetStateAction } from "react";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";

type KeyboardWrapperProps = {
  keyboardRef: MutableRefObject<typeof Keyboard>;
  setTargetInputValue: Dispatch<SetStateAction<string>>;
};

const SimpleKeyboardWrapper = ({
  keyboardRef,
  setTargetInputValue,
}: KeyboardWrapperProps) => {
  // State
  const [layoutName, setLayoutName] = useState("default");

  // Handle key press
  const handleKeyPress = (button: string, e?: MouseEvent): any => {
    // Prevent default
    e?.preventDefault();
    
    if (button === "{shift}" || button === "{lock}") {
      // Set layout name
      setLayoutName(layoutName === "default" ? "shift" : "default");
    }
    if (button === "{numbers}" || button === "{abc}") {
      // Set layout name
      setLayoutName(layoutName === "numbers" ? "default" : "numbers");
    }

    document.getElementById("keyboard-target")?.focus();
  };

  // Layout
  const layout = {
    default: [
      "q w e r t y u i o p",
      "a s d f g h j k l",
      "{shift} z x c v b n m {backspace}",
      "{numbers} {space} {ent}",
    ],
    shift: [
      "Q W E R T Y U I O P",
      "A S D F G H J K L",
      "{shift} Z X C V B N M {backspace}",
      "{numbers} {space} {ent}",
    ],
    numbers: ["1 2 3", "4 5 6", "7 8 9", "{abc} 0 {backspace}"],
  };

  const display = {
    "{numbers}": "123",
    "{ent}": "return",
    "{escape}": "esc ⎋",
    "{tab}": "tab ⇥",
    "{backspace}": "⌫",
    "{capslock}": "caps lock ⇪",
    "{shift}": "⇧",
    "{controlleft}": "ctrl ⌃",
    "{controlright}": "ctrl ⌃",
    "{altleft}": "alt ⌥",
    "{altright}": "alt ⌥",
    "{metaleft}": "cmd ⌘",
    "{metaright}": "cmd ⌘",
    "{abc}": "ABC",
  };

  return (
    <Box className="SimpleKeyboardWrapper">
      <Keyboard
        display={display}
        layout={layout}
        keyboardRef={(r: any) => (keyboardRef.current = r)}
        layoutName={layoutName}
        onChange={setTargetInputValue}
        onKeyPress={handleKeyPress}
      />
    </Box>
  );
};

export default SimpleKeyboardWrapper;
